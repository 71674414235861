import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useDispatch } from 'react-redux';

import { EntityDetail } from '../../types/entityTypes';
import { TAX_ID_VISIBILITY_LEVEL } from '../../constants/constants';
import { getTaxId } from '../../redux/feature/id-process/entity/entitySlice';

//Interface
interface TaxIdDetailsProps {
  taxId: string;
  selectedEntity: EntityDetail;
}

const TaxIdDetails = ({ taxId, selectedEntity }: TaxIdDetailsProps) => {
  //Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //State variables
  const [showEyeIcon, setShowEyeIcon] = useState(true);

  //UseEffects
  useEffect(() => {
    const data = {
      entity_uid: selectedEntity?.trellis_uid,
    };
    dispatch(getTaxId(data));
  }, []);

  //Methods
  const toggleEyeIcon = async () => {
    setShowEyeIcon(!showEyeIcon);
    let data: any;
    if (showEyeIcon) {
      data = {
        entity_uid: selectedEntity?.trellis_uid,
        params: {
          visibility_level: TAX_ID_VISIBILITY_LEVEL.SOURCE,
        },
      };
    }
    if (!showEyeIcon) {
      data = {
        entity_uid: selectedEntity?.trellis_uid,
      };
    }
    await dispatch(getTaxId(data));
  };

  return (
    <Box>
      <Box sx={{ pt: 6 }} className="text-medium textColor-400">
        {t('taxId')}
      </Box>
      <Box className="flex-basic-start">
        <Box className="font-weight-medium text-large">{taxId}</Box>
        <Box sx={{ px: 5 }} onClick={toggleEyeIcon} className="textColor-200 icon-size-10 cursorPointer">
          {showEyeIcon ? <VisibilityOutlinedIcon fontSize="medium" /> : <VisibilityOffOutlinedIcon fontSize="medium" />}
        </Box>
      </Box>
    </Box>
  );
};

export default TaxIdDetails;
