import React, { useEffect, useState } from 'react';
import { Box, FormControl, Grid, Icon, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useDispatch, useSelector } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  MAX_CHAR_LIMIT,
  MERCHANT_USER_ROLES,
  PORTAL,
  TAutocomplete,
  TButton,
  TDialog,
  TLoader,
  TSelect,
  TTextField,
  theme,
} from '../myde-react-components';
import { useTheme } from '../../providers/custom-theme-provider';
import { hexToRgbA } from '../myde-react-components/src/utils/commonMethods';
import { AdminUserResultType, CoordinatorType, EntityDetail } from '../../types/entityTypes';
import { ApplicationType } from '../../types/applicantTypes';
import {
  assignCoordinatorToEntity,
  getAdminUserList,
  getManageEntityList,
  getSearchedEntityList,
  selectEntity,
  setSearchedEntityList,
} from '../../redux/feature/id-process/entity/entitySlice';
import EntitySummaryCard from './EntitySummaryCard';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from '../../constants/routes';
import useQuery from '../../hooks/useQuery';
import { titleCase } from '../../utils/utils';

interface StyleProps {
  currentThemes: Theme;
}

//Styles
const useStyles = makeStyles<Theme, StyleProps>({
  primaryColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  primaryBackgorund: {
    background: ({ currentThemes }) => hexToRgbA(`${currentThemes.palette.primary.main}`, 0.05),
  },
});

interface AssignCoordinatorProps {
  applicationInfo: ApplicationType;
}

const AssignCoordinator = ({ applicationInfo }: AssignCoordinatorProps) => {
  //Constants
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { routeTo } = useRouter();
  const query = useQuery();
  const applicationId = query.get('inviteId') || '';

  //Redux
  const { manageEntityList, coordinatorsList, loading, searchedEntityList } = useSelector(selectEntity);

  //State variables
  const [showDialog, setShowDialog] = useState(false);
  const [coordinatorList, setCoordinatorList] = useState([] as CoordinatorType[]);
  const [selectedCoordinator, setSelectedCoordinator] = useState({
    id: '',
    name: '',
  });
  const [entityList, setEntityList] = useState([] as EntityDetail[]);
  const [searchedEntityTitle, setSearchedEntityTitle] = useState([] as EntityDetail[]);
  const [enteredComment, setEnteredComment] = useState('');

  //Use Effects
  useEffect(() => {
    const data = {
      role: MERCHANT_USER_ROLES.COORDINATOR,
    };
    dispatch(getAdminUserList(data));
    dispatch(getSearchedEntityList());
  }, []);

  useEffect(() => {
    if (searchedEntityList?.length > 0) {
      setSearchedEntityTitle(searchedEntityList);
    }
  }, [searchedEntityList]);

  useEffect(() => {
    if (applicationId) {
      getUpdatedData();
    }
  }, [applicationId]);

  useEffect(() => {
    manageEntityList?.length > 0 ? setEntityList(manageEntityList) : setEntityList([]);
  }, [manageEntityList]);

  useEffect(() => {
    const result = coordinatorsList?.map((item: AdminUserResultType) => {
      return {
        id: item?.id,
        name: `${item?.first_name} ${item?.last_name}`,
      };
    });
    setCoordinatorList(result);
  }, [coordinatorsList]);

  //Schema
  const AssignCoordinatorFormSchema = z.object({
    entity_name: z
      .string()
      .min(1, { message: 'Entity Name is required' })
      .refine(
        (value) =>
          !searchedEntityTitle.some(
            (el: EntityDetail) =>
              titleCase(el?.entity_name).toUpperCase().trim() === titleCase(value).toUpperCase().trim(),
          ),
        {
          message: 'Entity name should be unique',
        },
      ),
    comments: z.string().optional(),
    coordinator: z.string().min(1, { message: 'Coordinator is required' }),
  });

  type AssignCoordinatorFormSchemaPayload = z.infer<typeof AssignCoordinatorFormSchema>;
  const { handleSubmit, control, formState, setValue, reset, setError, getValues } =
    useForm<AssignCoordinatorFormSchemaPayload>({
      resolver: zodResolver(AssignCoordinatorFormSchema),
      mode: 'onChange',
    });
  const { errors, isValid, isSubmitting } = formState;

  //Methods
  const getUpdatedData = async () => {
    const data = {
      application_id: applicationId,
    };
    await dispatch(getManageEntityList(data));
  };

  const handleCancelClick = () => {
    setShowDialog(false);
    setSelectedCoordinator({ id: '', name: '' });
    dispatch(setSearchedEntityList([]));
    setEnteredComment('');
    reset();
  };

  const onSubmit = async (formData: AssignCoordinatorFormSchemaPayload) => {
    await assignCoordinator(formData);
    handleCancelClick();
    await getUpdatedData();
  };

  const headerClickHandler = () => {
    const stateObj = { applicationId: applicationInfo?.id };
    routeTo(ROUTES.ENTITIES, true, '', stateObj);
  };

  const assignCoordinator = async (formData: AssignCoordinatorFormSchemaPayload) => {
    const data = {
      entity_name: formData?.entity_name,
      comment: formData?.comments || '',
      assigned_to_id: selectedCoordinator?.id,
      application_id: applicationInfo?.id,
      details: {},
    };
    await dispatch(assignCoordinatorToEntity(data));
  };

  const handleTypeChange = (_event: React.SyntheticEvent, value: any) => {
    if (value) {
      setSelectedCoordinator(value);
      setValue('coordinator', value?.id, { shouldValidate: true });
    } else {
      setSelectedCoordinator({ id: '', name: '' });
      setError('coordinator', { type: 'custom', message: 'Coordinator is required' });
    }
  };

  const handleAddButton = () => {
    setShowDialog(true);
  };

  const handleCommentChange = (event: any) => {
    setValue('comments', event?.target?.value, { shouldValidate: true });
    setEnteredComment(event?.target?.value);
  };

  return (
    <Box>
      <TLoader loading={loading} />
      {entityList?.length === 0 ? (
        <Box
          sx={{ pt: theme.spacing(8), pb: theme.spacing(8) }}
          className={clsx('flex-basic-center flex-direction-column blankComponentContainer', classes.primaryBackgorund)}
        >
          <Box
            className={clsx(classes.primaryColor, 'cursorPointer')}
            sx={{ mb: theme.spacing(3) }}
            onClick={() => setShowDialog(true)}
          >
            <AddCircleIcon className="icon-size-40" />
          </Box>
          <Box sx={{ mb: theme.spacing(3) }} className="iconWithTextColor text-large">
            {t('addEntity')}
          </Box>
          <Box className={'text-small'}>{t('addEntityText')}</Box>
        </Box>
      ) : (
        <>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
            <Box className="flex-basic-start">
              <Box>
                <label className={'text-h3 textColor-200 font-weight-semibold'}>{t('entitiesLabel')}</label>
              </Box>
              <Box sx={{ mt: 1 }} onClick={headerClickHandler}>
                <Icon sx={{ ml: 1 }} className={'cursorPointer textColor-200'}>
                  chevron_right_icon
                </Icon>
              </Box>
            </Box>

            <Grid item>
              <TButton
                icon={<Box sx={{ pr: 1 }} className={'icon-add'} />}
                variant="text"
                btnText={'Add Entities'}
                onClick={handleAddButton}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ mb: 1 }}>
            <Grid item md={12}>
              <EntitySummaryCard entitiesList={entityList} />
            </Grid>
          </Grid>
        </>
      )}
      <TDialog open={showDialog} portalName={PORTAL.MERCHANT} onClose={handleCancelClick} title={t('addEntityLabel')}>
        <form>
          <Box className="text-small">{t('assignCoordinatorFormText')}</Box>
          <Grid container spacing={1}>
            <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="entity_name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    label={t('entityName')}
                    placeholder={'Type Title'}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: MAX_CHAR_LIMIT.SUMMARY_STRING_LENGTH }}
                    error={!!errors?.entity_name}
                    helperText={errors?.entity_name?.message}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="coordinator"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <FormControl className="w-100">
                    <TAutocomplete
                      {...field}
                      value={selectedCoordinator}
                      fullWidth
                      options={coordinatorList || []}
                      getOptionLabel={(option) => option?.name || ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={handleTypeChange}
                      renderInput={(params) => (
                        <TTextField placeholder={'Coordinator Name'} label={t('assignCoordinator')} {...params} />
                      )}
                    />
                  </FormControl>
                )}
              />
            </Grid>

            <Grid sx={{ mt: 1 }} item xs={12} sm={12} md={12} lg={12}>
              <Controller
                name="comments"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    label={t('comments')}
                    placeholder={'Description'}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4}
                    inputProps={{ maxLength: MAX_CHAR_LIMIT.CHAR_LENGTH }}
                    error={!!errors?.comments}
                    helperText={errors?.comments?.message}
                    {...field}
                    onChange={handleCommentChange}
                  />
                )}
              />
            </Grid>
            <Grid className="flex-basic-end text-small font-weight-medium" item xs={12} sm={12} md={12} lg={12}>
              {`${MAX_CHAR_LIMIT?.CHAR_LENGTH - enteredComment?.length} character(s) remaining`}
            </Grid>
          </Grid>
          <Box className="flex-basic-end" sx={{ mt: 3 }}>
            <TButton
              btnText="Save"
              btnWidthSize="button-w-140"
              variant="contained"
              disabled={!isValid || isSubmitting}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      </TDialog>
    </Box>
  );
};

export default AssignCoordinator;
