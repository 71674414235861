import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../..';

import {
  getIdProcessDetailsByProcessId,
  getAllEntities,
  editAnEntity,
  updateEntityProfileDetails,
  uploadAvatar,
  createNewEntity,
  getEnteredTaxId,
  addTaxId,
  submitIdProcess,
  getEntityIdProcessDetailsByProcessId,
  getAllAssignedEntityDetails,
  getAllAssignedEntitiesList,
  assignCoordiantor,
  getListOfAdminUser,
} from '../../../../api/idProcesses';
import {
  AddedEntityType,
  EditEntityType,
  EntityFormType,
  EntityMemberDetails,
  TaxIdType,
  EditEntityInfoPayloadType,
  EntityDetail,
  AssignCoordinatorFormDataType,
  AdminUserResultType,
} from '../../../../types/entityTypes';
import { deleteMember, getAllEntityMembersByRole, sendMemberInvites, updateMember } from '../../../../api/entity';

interface EntityIdProcesses {
  loading: boolean;
  error: boolean;
  errorObj: object;
  entityIdProcessDetails: any; //This variable will have details of all the entity id process, hence type is any
  allEntityMembers: EntityMemberDetails[];
  deleteMemberSuccessMessage: string;
  updatedMemberDetails: EntityMemberDetails;
  inviteSendSuccessMessage: string;
  addedEntity: AddedEntityType;
  entityList: AddedEntityType[];
  manageEntityList: EntityDetail[];
  assignedEntityDetails: EntityDetail;
  manageEntityListCount: number;
  isEditable: boolean;
  specificEntityIdProcessDetails: any; //This variable will store the information of all id processes.
  taxId: string;
  successfulIdProcessDetails: any;
  entityProfileURL: string;
  assignmentID: number;
  allExistingEntityMembersList: EntityMemberDetails[];
  accreditationStatus: string;
  coordinatorsList: AdminUserResultType[];
  searchedEntityList: EntityDetail[];
  unmaskedTaxId: string;
}

const initialState: EntityIdProcesses = {
  loading: false,
  error: false,
  errorObj: <any>{},
  entityIdProcessDetails: <any>{},
  allEntityMembers: [] as EntityMemberDetails[],
  deleteMemberSuccessMessage: '',
  updatedMemberDetails: <EntityMemberDetails>{},
  inviteSendSuccessMessage: '',
  addedEntity: <AddedEntityType>{},
  entityList: [] as AddedEntityType[],
  manageEntityList: [] as EntityDetail[],
  assignedEntityDetails: {} as EntityDetail,
  manageEntityListCount: 0,
  isEditable: false,
  assignmentID: <any>{},
  successfulIdProcessDetails: {},
  entityProfileURL: '',
  specificEntityIdProcessDetails: {},
  taxId: '',
  allExistingEntityMembersList: [],
  accreditationStatus: '',
  coordinatorsList: [] as AdminUserResultType[],
  searchedEntityList: [] as EntityDetail[],
  unmaskedTaxId: '',
};

export const selectEntity = ({ entity }: RootState) => ({
  loading: entity.loading,
  error: entity.error,
  errorObj: entity.errorObj,
  entityIdProcessDetails: entity.entityIdProcessDetails,
  allEntityMembers: entity.allEntityMembers,
  deleteMemberSuccessMessage: entity.deleteMemberSuccessMessage,
  updatedMemberDetails: entity.updatedMemberDetails,
  inviteSendSuccessMessage: entity.inviteSendSuccessMessage,
  addedEntity: entity.addedEntity,
  entityList: entity.entityList,
  manageEntityListCount: entity.manageEntityListCount,
  manageEntityList: entity.manageEntityList,
  assignedEntityDetails: entity.assignedEntityDetails,
  isEditable: entity.isEditable,
  successfulIdProcessDetails: entity.successfulIdProcessDetails,
  entityProfileURL: entity.entityProfileURL,
  specificEntityIdProcessDetails: entity.specificEntityIdProcessDetails,
  assignmentID: entity.assignmentID,
  taxId: entity.taxId,
  allExistingEntityMembersList: entity.allExistingEntityMembersList,
  accreditationStatus: entity.accreditationStatus,
  coordinatorsList: entity.coordinatorsList,
  searchedEntityList: entity.searchedEntityList,
  unmaskedTaxId: entity.unmaskedTaxId,
});

//actions
export const getEntityIdProcessDetails = createAsyncThunk(
  'entity/getEntityIdProcessDetails',
  async (data: { applicationId: string; processId: number | string }) => {
    return await getIdProcessDetailsByProcessId(data?.applicationId, data?.processId);
  },
);

export const getEntityMembersByRole = createAsyncThunk(
  'entity/getEntityMembersByRole',
  async (data: { entity_uid: number; filters?: object }) => {
    return await getAllEntityMembersByRole(data?.entity_uid, data?.filters);
  },
);

export const deleteMemberById = createAsyncThunk(
  'entity/deleteMemberById',
  async (data: { entity_uid: number; trellis_uid: number }) => {
    return await deleteMember(data?.entity_uid, data?.trellis_uid);
  },
);

export const updateMemberById = createAsyncThunk(
  'entity/updateMemberById',
  async (data: { entity_uid: number; trellis_uid: number; payload: object }) => {
    return await updateMember(data?.entity_uid, data?.trellis_uid, data?.payload);
  },
);

export const sendInviteToAllEntityMembers = createAsyncThunk(
  'entity/sendMemberInvites',
  async (data: { entity_uid: number; payload: object }) => {
    return await sendMemberInvites(data?.entity_uid, data?.payload);
  },
);

export const createEntity = createAsyncThunk('entity/createEntity', async (data: EntityFormType) => {
  return await createNewEntity(data);
});

export const getEntityList = createAsyncThunk('entity/getEntityList', async (params?: object) => {
  return await getAllEntities(params);
});

export const getManageEntityList = createAsyncThunk('entity/getManageEntityList', async (params?: object) => {
  return await getAllAssignedEntitiesList(params);
});

export const getEntityDetailById = createAsyncThunk('entity/getEntityDetailById', async (entityId: number) => {
  return await getAllAssignedEntityDetails(entityId);
});

export const getSelectedEntity = createAsyncThunk('entity/getSelectedEntity', async (params: object) => {
  return await getAllEntities(params);
});

export const editEntity = createAsyncThunk(
  'entity/editEntity',
  async (data: { entity_uid: number; formData: EditEntityType }) => {
    return await editAnEntity(data);
  },
);

export const submitEntityIdProcess = createAsyncThunk(
  'entity/submitEntityIdProcess',
  async (data: { entity_uid: number; payload: object }) => {
    return await submitIdProcess(data?.entity_uid, data?.payload);
  },
);

export const updateEntityDetails = createAsyncThunk(
  'entity/updateEntityDetails',
  async (payload: { entityUid: number; formData: EditEntityInfoPayloadType }) => {
    return await updateEntityProfileDetails(payload?.entityUid, payload?.formData);
  },
);

export const uploadEntityAvatarFile = createAsyncThunk('entity/uploadEntityAvatarFile', async (file: any) => {
  return await uploadAvatar(file);
});

export const getSpecificIdProcessDetailsByProcessId = createAsyncThunk(
  'entity/getSpecificIdProcessDetailsByProcessId',
  async (data: { entity_uid: number; process_id: number }) => {
    return await getEntityIdProcessDetailsByProcessId(data?.entity_uid, data?.process_id);
  },
);

export const addNewTaxId = createAsyncThunk(
  'entity/addNewTaxId',
  async (data: { entity_uid: number; formData: TaxIdType }) => {
    return await addTaxId(data);
  },
);

export const getTaxId = createAsyncThunk('entity/getTaxId', async (data: { entity_uid: number; params?: object }) => {
  return await getEnteredTaxId(data);
});

export const getUnmaskedTaxId = createAsyncThunk(
  'entity/getUnmaskedTaxId',
  async (data: { entity_uid: number; params?: object }) => {
    return await getEnteredTaxId(data);
  },
);

export const getAllExistingEntityMembers = createAsyncThunk(
  'entity/getAllExistingEntityMembers',
  async (entity_uid: number) => {
    return await getAllEntityMembersByRole(entity_uid);
  },
);

export const assignCoordinatorToEntity = createAsyncThunk(
  'entity/assignCoordinatorToEntity',
  async (data: AssignCoordinatorFormDataType) => {
    return await assignCoordiantor(data);
  },
);

export const getAdminUserList = createAsyncThunk('entity/getAdminUserList', async (params: object) => {
  return await getListOfAdminUser(params);
});

export const getSearchedEntityList = createAsyncThunk('entity/getSearchedEntityList', async (params?: object) => {
  return await getAllAssignedEntitiesList(params);
});

//reducer
export const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    resetEntityIdProcessDetails: (state) => {
      state.entityIdProcessDetails = {};
    },
    resetDeleteMemberSuccessMessage: (state) => {
      state.deleteMemberSuccessMessage = '';
    },
    resetUpdatedMemberDetails: (state) => {
      state.updatedMemberDetails = {} as EntityMemberDetails;
    },
    setIsEditable: (state, action) => {
      state.isEditable = action.payload;
    },
    setAssignedEntityDetails: (state, action) => {
      state.assignedEntityDetails = action.payload;
    },
    resetSuccessIdProcessMessage: (state) => {
      state.successfulIdProcessDetails = {} as any;
    },
    setSpecificEntityIdProcessDetails: (state, action) => {
      state.specificEntityIdProcessDetails = action.payload;
    },
    resetAddedEntity: (state) => {
      state.addedEntity = {} as EntityDetail;
    },
    setTaxId: (state, action) => {
      state.taxId = action.payload;
    },
    setMerchantEntityDetails: (state, action) => {
      state.manageEntityList = action.payload;
    },
    setMerchantEntityDetailsCount: (state, action) => {
      state.manageEntityListCount = action.payload;
    },
    setSearchedEntityList: (state, action) => {
      state.searchedEntityList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEntityIdProcessDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityIdProcessDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.entityIdProcessDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityIdProcessDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getEntityMembersByRole.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityMembersByRole.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.allEntityMembers = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityMembersByRole.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      //add a new entity
      .addCase(createEntity.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.addedEntity = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(createEntity.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteMemberById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(deleteMemberById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.deleteMemberSuccessMessage = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(deleteMemberById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      //list created entities
      .addCase(getEntityList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.entityList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      //Manage list entities
      .addCase(getManageEntityList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getManageEntityList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.manageEntityList = action.payload.results;
          state.manageEntityListCount = action.payload.count;
        } else {
          state.error = true;
        }
      })
      .addCase(getManageEntityList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(getEntityDetailById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getEntityDetailById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.assignedEntityDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getEntityDetailById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      //update entity profile details
      .addCase(updateEntityDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateEntityDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.entityProfileURL = action.payload?.avatar_url;
        } else {
          state.error = true;
        }
      })
      .addCase(updateEntityDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getSpecificIdProcessDetailsByProcessId.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSpecificIdProcessDetailsByProcessId.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload?.data?.accreditation_status) {
          state.accreditationStatus = action.payload?.data?.accreditation_status;
        } else if (action.payload) {
          state.specificEntityIdProcessDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getSpecificIdProcessDetailsByProcessId.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateMemberById.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(updateMemberById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.updatedMemberDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(updateMemberById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(uploadEntityAvatarFile.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(uploadEntityAvatarFile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.entityProfileURL = action.payload?.url;
        } else {
          state.error = true;
        }
      })
      .addCase(uploadEntityAvatarFile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })

      .addCase(sendInviteToAllEntityMembers.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(sendInviteToAllEntityMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.inviteSendSuccessMessage = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(sendInviteToAllEntityMembers.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      //get tax id
      .addCase(getTaxId.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getTaxId.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.taxId = action.payload.tax_id;
        } else {
          state.error = true;
        }
      })
      .addCase(getTaxId.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(submitEntityIdProcess.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(submitEntityIdProcess.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.successfulIdProcessDetails = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(submitEntityIdProcess.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAllExistingEntityMembers.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllExistingEntityMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.allExistingEntityMembersList = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(getAllExistingEntityMembers.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(editEntity.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(editEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.addedEntity = action.payload;
        } else {
          state.error = true;
        }
      })
      .addCase(editEntity.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      //get admin users list
      .addCase(getAdminUserList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAdminUserList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.coordinatorsList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getAdminUserList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      //get searched entity name results
      .addCase(getSearchedEntityList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getSearchedEntityList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.searchedEntityList = action.payload.results;
        } else {
          state.error = true;
        }
      })
      .addCase(getSearchedEntityList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      //get unmasked tax id
      .addCase(getUnmaskedTaxId.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUnmaskedTaxId.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        if (action.payload) {
          state.unmaskedTaxId = action.payload.tax_id;
        } else {
          state.error = true;
        }
      })
      .addCase(getUnmaskedTaxId.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  resetEntityIdProcessDetails,
  resetDeleteMemberSuccessMessage,
  resetUpdatedMemberDetails,
  setIsEditable,
  setAssignedEntityDetails,
  setSpecificEntityIdProcessDetails,
  resetAddedEntity,
  setTaxId,
  setMerchantEntityDetails,
  setMerchantEntityDetailsCount,
  setSearchedEntityList,
  resetSuccessIdProcessMessage,
} = entitySlice.actions;

export default entitySlice.reducer;
