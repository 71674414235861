import React, { useEffect, useState } from 'react';
import { Box, Chip, FormControlLabel, FormGroup, Grid, Icon, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  ACCEPTED_CSV_MIME_TYPES,
  colorPalette,
  FileButton,
  MAX_CHAR_LIMIT,
  PROCESS_IDS,
  TAlertDialog,
  TAutocomplete,
  TButton,
  TCard,
  TCheckbox,
  theme,
  TLoader,
  TSuccessDialog,
  TTextArea,
  TTextField,
  TTooltip,
} from '../myde-react-components';
import * as ROUTES from '../../constants/routes';
import { useRouter } from '../../providers/custom-router-provider';
import { InviteFormSchema, InviteFormSchemaPayload } from './validation';
import {
  ID_PROCESS_WITH_SAFE_DOC,
  ENTITY_OWNER_ID_PROCESS_HIGH_PRIORITY,
  USER_TYPE,
  ACCESS_LEVEL_VALUE,
  PROFILE_ADDRESS_TYPES,
  PROFILE_ADDRESS_TYPE_LABEL,
  DEFAULT_PROFILE_ADDRESS_TYPE,
  PROCESS_IDS_EXCLUSION_LIST,
  EMAIL_REGEX,
  DOCUMENT_UPLOAD_TYPE,
} from '../../constants/constants';
import {
  getMerchantTApps,
  selectMerchantInvite,
  setIsCsvUploaded,
  setIsFormValid,
  setTAppFormData,
  setTAppFormDataWithoutFields,
  setTAppFieldData,
  listCustomIdRequirement,
  getEntityIdProcesses,
  getIndividualIdProcesses,
  getInviteTemplatesList,
  setEditLibraryData,
} from '../../redux/feature/merchantInvite/merchantInviteSlice';
import {
  IdProcessResultType,
  IndividualIdRequirementsType,
  EntityIdRequirementsType,
  InviteFieldsType,
  InviteRecipientsType,
  TAppResultType,
  TAppSelectionType,
  CustomIdRequirementSelectionType,
  CustomIdResultType,
  TAppFormInviteFields,
  OwnerIdRequirementsType,
  IdRequirementsPayloadType,
  ProfileAddressRequirementsType,
  IdRequirementResultType,
  MerchantInviteAttributeType,
  TAppFormData,
  ProfileAddressRequirementType,
  LibraryDocumentSelectionType,
} from '../../types/inviteTypes';
import { useTheme } from '../../providers/custom-theme-provider';
import RecipientForm from './RecipientForm';
import { createInviteTemplate, inviteEntity, inviteUser, updateInviteTemplateById } from '../../api/invitations';
import AddIdRequirements from './AddIdRequirements';
import { setCurrentTabId } from '../../redux/feature/merchantInvite/inviteSlice';
import TAppForm from './TAppForm';
import IndividualIdRequirements from './IndividualIdRequirements';
import EntityIdRequirements from './EntityIdRequirements';
import OwnerIdRequirements from './OwnerIdRequirements';
import { PORTAL } from '../myde-react-components/src/constants/portal';
import LibraryDocumentForm from './LibraryDocumentForm';

interface StyleProps {
  currentThemes: Theme;
}

// styles
const useStyles = makeStyles<Theme, StyleProps>(() => ({
  recipientList: {
    '& .MuiFormControl-root': {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-input': {
          minWidth: '0 !important',
        },
        '& .MuiAutocomplete-endAdornment': {
          '& .MuiButtonBase-root': {
            padding: '0 !important',
          },
        },
        '& .MuiAutocomplete-tag': {
          marginBottom: '4px !important',
        },
      },
    },
  },
  dropdownStyle: {
    '& .MuiAutocomplete-popper': {
      display: 'none',
    },
  },
  truncateText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  btnColor: {
    color: ({ currentThemes }) => currentThemes.palette.primary.main,
  },
  disabledState: {
    color: colorPalette.typoText.disabled,
  },
  titleBorder: {
    '&:after': {
      backgroundColor: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
    },
  },
  gridStyle: {
    '&.MuiGrid-item ': {
      paddingTop: '0px !important',
    },
  },
}));

const InviteForm = () => {
  // state variables
  const [recipients, setRecipients] = useState([] as InviteRecipientsType[]);
  const [recipientInput, setRecipientInput] = useState('');
  const [emailRecipientList, setEmailRecipientList] = useState([] as string[]);
  const [isRecipientEmailValid, setIsRecipientEmailValid] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState({} as File);
  const [openRecipients, setOpenRecipients] = useState(false);
  const [showClearAll, setShowClearAll] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [isAutoApproved, setIsAutoApproved] = useState(false);
  const [individualRequirements, setIndividualRequirements] = useState([] as IndividualIdRequirementsType[]);
  const [selectedIndividualRequirements, setSelectedIndividualRequirements] = useState(
    [] as IndividualIdRequirementsType[],
  );
  const [entityRequirements, setEntityRequirements] = useState([] as EntityIdRequirementsType[]);
  const [selectedEntityRequirements, setSelectedEntityRequirements] = useState([] as EntityIdRequirementsType[]);
  const [ownerRequirements, setOwnerRequirements] = useState([] as OwnerIdRequirementsType[]);
  const [selectedOwnerRequirements, setSelectedOwnerRequirements] = useState([] as OwnerIdRequirementsType[]);
  const [customRequirements, setCustomRequirements] = useState([] as CustomIdRequirementSelectionType[]);
  const [selectedCustomRequirements, setSelectedCustomRequirements] = useState(
    [] as CustomIdRequirementSelectionType[],
  );
  const [libraryCustomRequirements, setLibraryCustomRequirements] = useState([] as LibraryDocumentSelectionType[]);
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [showSendInviteAlert, setShowSendInviteAlert] = useState(false);
  const [openIdRequirementsModal, setOpenIdRequirementsModal] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [merchantTAppData, setMerchantTAppData] = useState([] as TAppResultType[]);
  const [tApps, setTApps] = useState([] as TAppSelectionType[]);
  const [duplicateTAppList, setDuplicateTAppList] = useState([] as TAppSelectionType[]);
  const [titleCount, setTitleCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [editCustomRequirement, setEditCustomRequirement] = useState({} as CustomIdRequirementSelectionType);
  const [editLibraryCustomRequirement, setEditLibraryCustomRequirement] = useState({} as LibraryDocumentSelectionType);
  const [isEdit, setIsEdit] = useState(false);
  const [isEntityInvitation, setIsEntityInvitation] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openLibraryDocumentForm, setOpenLibraryDocumentForm] = useState(false);

  // constants
  const { t } = useTranslation('common');
  const { routeTo } = useRouter();
  const { goBack } = useRouter();
  const dispatch = useDispatch();
  const { currentThemes } = useTheme();
  const styleProps = {
    currentThemes,
  };
  const classes = useStyles(styleProps);

  // Form Schema Setup
  const { handleSubmit, control, formState, setValue } = useForm<InviteFormSchemaPayload>({
    resolver: zodResolver(InviteFormSchema),
    mode: 'onChange',
  });
  const { errors, isValid, isSubmitting } = formState;

  // use selectors
  const {
    specificInviteTemplate,
    individualIdProcessData,
    entityIdProcessData,
    isCsvUploaded,
    loading,
    merchantTApps,
    isFormValid,
    tAppFormData,
    tAppFormDataWithoutFields,
    customIdRequirementList,
    isEntityInvite,
    templateId,
  } = useSelector(selectMerchantInvite);

  // Methods
  const closeForm = () => {
    goBack();
  };

  const handleRecipientChange = (data: InviteRecipientsType) => {
    const newRecipient = {
      id: recipients?.length + 1,
      ...data,
    };
    if (isCsvUploaded) {
      const previousRecipients = [...recipients];
      previousRecipients.splice(0, 1);
      setRecipients(previousRecipients);
      setFile({} as File);
      dispatch(setIsCsvUploaded(false));
      setEmailRecipientList([newRecipient?.email]);
      setRecipients([newRecipient]);
    } else {
      const combinedData = [...recipients, newRecipient];
      const emailDetailList = combinedData?.map((item) => item?.email);
      setEmailRecipientList(emailDetailList);
      setRecipients(combinedData);
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitleCount(event?.target?.value?.length);
    setTitle(event?.target?.value);
    setValue('title', event?.target?.value, { shouldValidate: true });
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageCount(event?.target?.value?.length);
    setMessage(event?.target?.value);
    setValue('custom_message', event?.target?.value, { shouldValidate: true });
  };

  const handleTCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAndConditions(event?.target?.checked);
  };

  const handleAutoApprovedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutoApproved(event?.target?.checked);
  };

  const handleChipDelete = (item: string) => {
    if (recipients?.length > 0) {
      const data = [...recipients];
      const recipientDataIdx = data.findIndex((element) => element?.email === item);
      data.splice(recipientDataIdx, 1);
      setEmailRecipientList(data?.map((item) => item?.email));
      setRecipients(data);
      setFile({} as File);
      dispatch(setIsCsvUploaded(false));
    }
  };

  const getProfileRequirements = (profileRequirements: ProfileAddressRequirementsType[]) => {
    const filterProfileRequirements = profileRequirements?.filter((item) => item?.is_checked);
    return filterProfileRequirements?.map((item) => {
      return {
        address_type: item?.address_type,
        is_supporting_document_required: item?.is_supporting_document_required,
      };
    });
  };

  const getBasicIdRequirements = () => {
    if (isEntityInvitation) {
      const filterEntityRequirements = entityRequirements?.filter((element) => element?.is_checked);
      return filterEntityRequirements?.map((element) => {
        return {
          process_id: element?.process_id,
          process_name: element?.process_name,
          access_level: ACCESS_LEVEL_VALUE.SOURCE,
          note: element?.note,
          is_mandatory: element?.is_mandatory,
        };
      });
    } else {
      const filterIndividualRequirements = individualRequirements?.filter((element) => element?.is_checked);
      return filterIndividualRequirements?.map((element) => {
        if (element?.process_id === PROCESS_IDS.PROFILE_VERIFICATION) {
          return {
            process_id: element?.process_id,
            process_name: element?.process_name,
            access_level: element?.access_level ? ACCESS_LEVEL_VALUE.SAFE_DOC : ACCESS_LEVEL_VALUE.SOURCE,
            note: element?.note,
            is_mandatory: element?.is_mandatory,
            profile_address_requirement_list: getProfileRequirements(element?.profile_address_requirement_list),
          };
        }
        return {
          process_id: element?.process_id,
          process_name: element?.process_name,
          access_level: element?.access_level ? ACCESS_LEVEL_VALUE.SAFE_DOC : ACCESS_LEVEL_VALUE.SOURCE,
          note: element?.note,
          is_mandatory: element?.is_mandatory,
        };
      });
    }
  };

  const getCustomIdRequirements = () => {
    const filterCustomRequirements = customRequirements?.filter((element) => element?.is_checked);
    return filterCustomRequirements?.map((element) => {
      if (element?.is_checked) {
        return {
          process_id: '',
          process_name: element?.process_name,
          access_level: ACCESS_LEVEL_VALUE.SOURCE,
          requirement_id: element?.process_id,
        };
      }
    });
  };

  const getLibraryCustomIdRequirements = () => {
    const filterCustomRequirements = libraryCustomRequirements?.filter((element) => element?.is_checked);
    return filterCustomRequirements?.map((element) => {
      if (element?.is_checked) {
        return {
          process_id: '',
          process_name: element?.process_name,
          access_level: ACCESS_LEVEL_VALUE.SOURCE,
          requirement_id: element?.process_id,
        };
      }
    });
  };

  const getOwnerIdRequirementsData = () => {
    const filterOwnerRequirements = ownerRequirements?.filter((element) => element?.is_checked);
    return filterOwnerRequirements?.map((item) => {
      if (item?.process_id === PROCESS_IDS.PROFILE_VERIFICATION) {
        return {
          process_id: item?.process_id,
          process_name: item?.process_name,
          access_level: item?.access_level ? ACCESS_LEVEL_VALUE.SAFE_DOC : ACCESS_LEVEL_VALUE.SOURCE,
          note: item?.note,
          is_mandatory: item?.is_mandatory,
          profile_address_requirement_list: getProfileRequirements(item?.profile_address_requirement_list),
        };
      }
      return {
        process_id: item?.process_id,
        process_name: item?.process_name,
        access_level: item?.access_level ? ACCESS_LEVEL_VALUE.SAFE_DOC : ACCESS_LEVEL_VALUE.SOURCE,
        note: item?.note,
        is_mandatory: item?.is_mandatory,
      };
    });
  };

  const getInviteAttributes = () => {
    const combineTAppFormData = [...tAppFormData, ...tAppFormDataWithoutFields];
    return {
      is_sponsored: !!termsAndConditions,
      is_auto_approved: !!isAutoApproved,
      tapp_id_list: combineTAppFormData,
      invite_message: message,
      invite_title: title,
    };
  };

  const getEntityTemplatePayload = () => {
    const idRequirementsData = getBasicIdRequirements();
    const ownerIdRequirementsData = getOwnerIdRequirementsData();
    const customIdRequirementData = getCustomIdRequirements();
    const combineRequirements = [...idRequirementsData, ...customIdRequirementData];
    return {
      type: USER_TYPE.ENTITY,
      owner_id_requirements: ownerIdRequirementsData,
      invite_attributes: getInviteAttributes(),
      id_requirements: combineRequirements,
    };
  };

  const getIndividualTemplatePayload = () => {
    const idRequirementsData = getBasicIdRequirements();
    const customIdRequirementData = getCustomIdRequirements();
    const combineRequirements = [...idRequirementsData, ...customIdRequirementData];
    return {
      type: USER_TYPE.INDIVIDUAL,
      invite_attributes: getInviteAttributes(),
      id_requirements: combineRequirements,
    };
  };

  const saveTemplate = async () => {
    const data = isEntityInvitation ? getEntityTemplatePayload() : getIndividualTemplatePayload();
    try {
      setLoader(true);
      const result = templateId ? await updateInviteTemplateById(templateId, data) : await createInviteTemplate(data);
      if (Object.keys(result)?.length > 0) {
        toast.success('Template Saved Successfully');
        const inviteType = isEntityInvitation ? USER_TYPE.ENTITY : USER_TYPE.INDIVIDUAL;
        dispatch(getInviteTemplatesList({ invite_type: inviteType }));
        setLoader(false);
      }
    } catch (err) {
      toast.error('Error in saving template');
    }
  };

  const onSubmit = async () => {
    try {
      let ownerIdRequirementsData: Array<IdRequirementsPayloadType> = [];
      if (isEntityInvitation) {
        ownerIdRequirementsData = getOwnerIdRequirementsData();
      }
      const idRequirementsData = getBasicIdRequirements();
      const customIdRequirementData = getCustomIdRequirements();
      const libraryIdRequirementData = getLibraryCustomIdRequirements();
      const combineRequirements = [...idRequirementsData, ...customIdRequirementData, ...libraryIdRequirementData];
      const inviteAttributes = getInviteAttributes();
      const formData = new FormData();
      if (!isCsvUploaded) {
        if (!isEntityInvitation) {
          formData.append('user_list', JSON.stringify(recipients));
        } else {
          formData.append('entity_list', JSON.stringify(recipients));
        }
      } else {
        formData.append('invite_csv_file', file);
      }
      if (isEntityInvitation) {
        formData.append('invite_type', USER_TYPE.ENTITY);
        formData.append('owner_id_requirements', JSON.stringify(ownerIdRequirementsData));
      }
      formData.append('invite_attributes', JSON.stringify(inviteAttributes));
      formData.append('id_requirements', JSON.stringify(combineRequirements));
      formData.append('save_as_template', JSON.stringify(saveAsTemplate));
      const result = isEntityInvitation ? await inviteEntity(formData) : await inviteUser(formData);
      if (Object.keys(result)?.length > 0) {
        setShowSendInviteAlert(false);
        setOpenSuccessDialog(true);
      } else {
        toast.error('Error in sending Invite(s)');
      }
    } catch (err) {
      toast.error('Error in sending Invite(s)');
    }
  };

  const handleOnFileChange = (uploadedFiles: any) => {
    if (uploadedFiles?.length > 0) {
      setFile(Object.assign(uploadedFiles[0]));
      const newRecipient = {
        id: recipients?.length + 1,
        first_name: '',
        middle_name: '',
        last_name: '',
        email: uploadedFiles[0]?.name,
      };
      const combinedData = [newRecipient];
      setEmailRecipientList([uploadedFiles[0]?.name]);
      setRecipients(combinedData);
      dispatch(setIsCsvUploaded(true));
    } else {
      toast.error(`Please upload a file with a valid format.`);
    }
  };

  const openRecipientsModal = () => {
    setOpenRecipients(true);
  };

  const clearData = () => {
    setRecipients([]);
    setEmailRecipientList([]);
    setShowClearAll(false);
  };

  const onCustomIdProcessChange = (item: CustomIdRequirementSelectionType) => {
    const data = customRequirements?.map((element: CustomIdRequirementSelectionType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          is_checked: !item?.is_checked,
          access_level: false,
        };
      }
      return {
        ...element,
      };
    });
    setCustomRequirements(data);
  };

  const onLibraryCustomIdProcessChange = (item: LibraryDocumentSelectionType) => {
    const data = libraryCustomRequirements?.map((element: LibraryDocumentSelectionType) => {
      if (element?.process_id === item?.process_id) {
        return {
          ...element,
          is_checked: !item?.is_checked,
          access_level: false,
        };
      }
      return {
        ...element,
      };
    });
    setLibraryCustomRequirements(data);
  };

  const addCustomRequirement = (data: CustomIdRequirementSelectionType) => {
    const combinedData = [data, ...customRequirements];
    setCustomRequirements(combinedData);
  };

  const addLibraryCustomRequirement = (data: LibraryDocumentSelectionType) => {
    const combinedData = [data, ...libraryCustomRequirements];
    setLibraryCustomRequirements(combinedData);
  };

  const getIndividualProcessesData = (idProcesses: IdProcessResultType[]) => {
    const data: Array<IndividualIdRequirementsType> = idProcesses?.map((item: IdProcessResultType) => {
      if (ID_PROCESS_WITH_SAFE_DOC.includes(item?.process_id)) {
        return {
          process_id: item?.process_id,
          process_name: item?.title,
          access_level: true,
          has_access_level: true,
          is_checked: false,
          is_mandatory: true,
          note: '',
          has_children: false,
          profile_address_requirement_list: [],
        };
      } else if (PROCESS_IDS.PROFILE_VERIFICATION === item?.process_id) {
        return {
          process_id: item?.process_id,
          process_name: item?.title,
          access_level: false,
          has_access_level: false,
          is_checked: false,
          is_mandatory: true,
          note: '',
          has_children: true,
          profile_address_requirement_list: DEFAULT_PROFILE_ADDRESS_TYPE,
        };
      }
      return {
        process_id: item?.process_id,
        process_name: item?.title,
        access_level: true,
        has_access_level: false,
        is_checked: false,
        is_mandatory: true,
        note: '',
        has_children: false,
        profile_address_requirement_list: [],
      };
    });
    return data;
  };

  const getOwnerIdRequirements = (processes: IdProcessResultType[]) => {
    const data: Array<OwnerIdRequirementsType> = processes?.map((item: IdProcessResultType) => {
      if (ENTITY_OWNER_ID_PROCESS_HIGH_PRIORITY.includes(item?.process_id)) {
        if (ID_PROCESS_WITH_SAFE_DOC.includes(item?.process_id)) {
          return {
            process_id: item?.process_id,
            process_name: item?.title,
            access_level: true,
            has_access_level: true,
            is_checked: false,
            is_mandatory: true,
            note: '',
            has_high_priority: true,
            has_children: false,
            profile_address_requirement_list: [],
          };
        } else if (PROCESS_IDS.PROFILE_VERIFICATION === item?.process_id) {
          return {
            process_id: item?.process_id,
            process_name: item?.title,
            access_level: false,
            has_access_level: false,
            is_checked: false,
            is_mandatory: true,
            note: '',
            has_high_priority: false,
            has_children: true,
            profile_address_requirement_list: DEFAULT_PROFILE_ADDRESS_TYPE,
          };
        } else {
          return {
            process_id: item?.process_id,
            process_name: item?.title,
            access_level: false,
            has_access_level: false,
            is_checked: false,
            is_mandatory: true,
            note: '',
            has_high_priority: true,
            has_children: false,
            profile_address_requirement_list: [],
          };
        }
      } else if (ID_PROCESS_WITH_SAFE_DOC.includes(item?.process_id)) {
        return {
          process_id: item?.process_id,
          process_name: item?.title,
          access_level: true,
          has_access_level: true,
          is_checked: false,
          is_mandatory: true,
          note: '',
          has_high_priority: false,
          has_children: false,
          profile_address_requirement_list: [],
        };
      }
      return {
        process_id: item?.process_id,
        process_name: item?.title,
        access_level: false,
        has_access_level: false,
        is_checked: false,
        is_mandatory: true,
        note: '',
        has_high_priority: false,
        has_children: false,
        profile_address_requirement_list: [],
      };
    });
    return data;
  };

  const getEntityProcessesData = (entityProcesses: IdProcessResultType[]) => {
    const data: Array<EntityIdRequirementsType> = entityProcesses?.map((item: IdProcessResultType) => {
      return {
        process_id: item?.process_id,
        process_name: item?.title,
        is_checked: false,
        is_mandatory: true,
        note: '',
      };
    });
    return data;
  };

  const getDataOnLoad = () => {
    dispatch(setIsCsvUploaded(false));
    dispatch(getMerchantTApps({}));
    dispatch(listCustomIdRequirement({ page: 1, limit: 1000, process_type: DOCUMENT_UPLOAD_TYPE.CUSTOM }));
    // Process ID Exclusion List constant is used to exclude the specific process IDs and get the rest
    dispatch(
      getIndividualIdProcesses({
        process_type: USER_TYPE.INDIVIDUAL,
        process_id_not_in: `{${PROCESS_IDS_EXCLUSION_LIST.toString()}}`,
        limit: 1000,
      }),
    );
    if (isEntityInvite) {
      dispatch(getInviteTemplatesList({ invite_type: USER_TYPE.ENTITY }));
      dispatch(
        getEntityIdProcesses({
          process_type: USER_TYPE.ENTITY,
          limit: 1000,
        }),
      );
    } else {
      dispatch(getInviteTemplatesList({ invite_type: USER_TYPE.INDIVIDUAL }));
    }
  };

  const handleTAppChange = (item: TAppSelectionType) => {
    const data = tApps?.map((element: TAppSelectionType) => {
      if (element?.id === item?.id && element?.invite_fields?.length > 0) {
        dispatch(setIsFormValid(false));
        return {
          ...element,
          is_checked: !item?.is_checked,
        };
      } else if (element?.id === item?.id && element?.invite_fields?.length === 0) {
        const payload = {
          id: item?.id,
          invite_fields: [] as TAppFormInviteFields[],
        };
        dispatch(setTAppFormDataWithoutFields([...tAppFormDataWithoutFields, payload]));
        dispatch(setIsFormValid(true));
        return {
          ...element,
          is_checked: !item?.is_checked,
        };
      }
      return {
        ...element,
      };
    });
    setTApps(data);
  };

  const goToInvites = () => {
    dispatch(setCurrentTabId(3));
    routeTo(ROUTES.DASHBOARD);
  };

  const editCustomProcess = (data: CustomIdRequirementSelectionType) => {
    setIsEdit(true);
    setEditCustomRequirement(data);
    setOpenIdRequirementsModal(true);
  };

  const editLibraryCustomProcess = (data: LibraryDocumentSelectionType) => {
    setIsEdit(true);
    dispatch(setEditLibraryData(data));
    setEditLibraryCustomRequirement(data);
    setOpenLibraryDocumentForm(true);
  };

  const updateCustomRequirements = () => {
    getDataOnLoad();
    setIsEdit(false);
    setEditCustomRequirement({} as CustomIdRequirementSelectionType);
  };

  const updateLibraryCustomRequirements = (data: LibraryDocumentSelectionType) => {
    const previousData = libraryCustomRequirements;
    const updatedLibraryDataIndex = previousData?.findIndex((item) => item?.process_id === data?.process_id);
    if (updatedLibraryDataIndex === -1) {
      previousData?.push(data);
    } else {
      previousData[updatedLibraryDataIndex] = data;
    }
    setLibraryCustomRequirements(previousData);
    setIsEdit(false);
    dispatch(setEditLibraryData({} as LibraryDocumentSelectionType));
    setEditLibraryCustomRequirement({} as LibraryDocumentSelectionType);
  };

  const closeIdRequirementForm = () => {
    setOpenIdRequirementsModal(false);
    setIsEdit(false);
    setEditCustomRequirement({} as CustomIdRequirementSelectionType);
  };

  const closeLibraryRequirementForm = () => {
    setOpenLibraryDocumentForm(false);
    setIsEdit(false);
    setEditLibraryCustomRequirement({} as LibraryDocumentSelectionType);
  };

  const updateIndividualRequirements = (data: IndividualIdRequirementsType[]) => {
    setIndividualRequirements(data);
  };

  const updateEntityRequirements = (data: EntityIdRequirementsType[]) => {
    setEntityRequirements(data);
  };

  const updateOwnerRequirements = (data: OwnerIdRequirementsType[]) => {
    setOwnerRequirements(data);
  };

  const checkIsProfileRequirementsFulfilled = () => {
    let isProfileRequirementFulfilled = false;
    const requirements = isEntityInvitation ? ownerRequirements : individualRequirements;
    if (
      requirements?.filter((item) => !item?.is_checked && item?.process_id === PROCESS_IDS.PROFILE_VERIFICATION)
        ?.length > 0
    ) {
      isProfileRequirementFulfilled = true;
    } else {
      const profileIdRequirement =
        requirements?.find((element) => element?.process_id === PROCESS_IDS.PROFILE_VERIFICATION) ||
        ({} as IndividualIdRequirementsType);
      const result = profileIdRequirement?.profile_address_requirement_list?.filter((item) => item?.is_checked);
      isProfileRequirementFulfilled = result?.length > 0;
    }
    return isProfileRequirementFulfilled;
  };

  const setInviteAttributesData = (attributes: MerchantInviteAttributeType) => {
    setTitle(attributes?.invite_title || '');
    setTitleCount(attributes?.invite_title?.length || 0);
    setValue('title', attributes?.invite_title || '', {
      shouldValidate: !attributes?.invite_title ? false : true,
    });
    setMessage(attributes?.invite_message || '');
    setMessageCount(attributes?.invite_message?.length || 0);
    setValue('custom_message', attributes?.invite_message || '', {
      shouldValidate: !attributes?.invite_message ? false : true,
    });
    setTermsAndConditions(attributes?.is_sponsored);
    setIsAutoApproved(attributes?.is_auto_approved);
  };

  const getProfileRequirementsDetails = (data: ProfileAddressRequirementType[]) => {
    let combinedProfileRequirements: ProfileAddressRequirementsType[] = [];
    if (data?.length === 1) {
      const nonExistingAddressType =
        data[0]?.address_type === PROFILE_ADDRESS_TYPES.RESIDENTIAL
          ? DEFAULT_PROFILE_ADDRESS_TYPE[1]
          : DEFAULT_PROFILE_ADDRESS_TYPE[0];

      const existingAddressType = {
        ...data[0],
        address_type_label:
          data[0]?.address_type === PROFILE_ADDRESS_TYPES.RESIDENTIAL
            ? PROFILE_ADDRESS_TYPE_LABEL.RESIDENTIAL
            : PROFILE_ADDRESS_TYPE_LABEL.NON_RESIDENTIAL,
        is_checked: true,
      };
      combinedProfileRequirements = [existingAddressType, nonExistingAddressType];
    } else {
      const result = data?.map((item) => {
        return {
          address_type: item?.address_type,
          address_type_label:
            item?.address_type === PROFILE_ADDRESS_TYPES.RESIDENTIAL
              ? PROFILE_ADDRESS_TYPE_LABEL.RESIDENTIAL
              : PROFILE_ADDRESS_TYPE_LABEL.NON_RESIDENTIAL,
          is_checked: true,
          is_supporting_document_required: item?.is_supporting_document_required,
        };
      });
      combinedProfileRequirements = [...result];
    }
    return combinedProfileRequirements;
  };

  const getBasicIndividualRequirements = (
    processes: IdRequirementResultType[],
    selectedProcesses: IndividualIdRequirementsType[],
  ) => {
    const data =
      processes?.map((item) => {
        return {
          process_id: item?.id_process?.process_id,
          process_name: item?.id_process?.title,
          access_level: item?.id_access_level?.access_level === 1 ? false : true,
          has_access_level: ID_PROCESS_WITH_SAFE_DOC.includes(item?.id_process?.process_id),
          is_checked: true,
          is_mandatory: item?.details?.is_mandatory,
          note: item?.details?.note,
          has_children: item?.details?.profile_address_requirement_list ? true : false,
          profile_address_requirement_list:
            item?.details?.profile_address_requirement_list &&
            item?.details?.profile_address_requirement_list?.length > 0
              ? getProfileRequirementsDetails(item?.details?.profile_address_requirement_list)
              : DEFAULT_PROFILE_ADDRESS_TYPE,
        };
      }) || ([] as IndividualIdRequirementsType[]);
    const masterRequirementsData = selectedProcesses;
    const copyIdProcessMasterData = new Set(data?.map(({ process_id }) => process_id));
    const combinedRequirements = [
      ...data,
      ...masterRequirementsData?.filter(({ process_id }) => !copyIdProcessMasterData?.has(process_id)),
    ];
    return combinedRequirements;
  };

  const getBasicEntityRequirements = (
    processes: IdRequirementResultType[],
    selectedProcesses: EntityIdRequirementsType[],
  ) => {
    const data =
      processes?.map((item) => {
        return {
          process_id: item?.id_process?.process_id,
          process_name: item?.id_process?.title,
          is_checked: true,
          is_mandatory: item?.details?.is_mandatory,
          note: item?.details?.note,
        };
      }) || ([] as EntityIdRequirementsType[]);
    const masterRequirementsData = selectedProcesses;
    const copyIdProcessMasterData = new Set(data?.map(({ process_id }) => process_id));
    const combinedRequirements = [
      ...data,
      ...masterRequirementsData?.filter(({ process_id }) => !copyIdProcessMasterData?.has(process_id)),
    ];
    return combinedRequirements;
  };

  const getSavedCustomRequirements = (
    processes: IdRequirementResultType[],
    selectedProcesses: CustomIdRequirementSelectionType[],
  ) => {
    const data =
      processes?.map((item) => {
        return {
          process_id: item?.custom_id_process?.id,
          process_name: item?.custom_id_process?.process_name,
          process_description: item?.custom_id_process?.process_description,
          access_level: item?.id_access_level?.access_level === 1 ? false : true,
          is_checked: true,
        };
      }) || ([] as CustomIdRequirementSelectionType[]);
    const masterCustomRequirementsData = selectedProcesses;
    const copyCustomIdProcessMasterData = new Set(data?.map(({ process_id }) => process_id));
    const combinedCustomRequirements = [
      ...data,
      ...masterCustomRequirementsData?.filter(({ process_id }) => !copyCustomIdProcessMasterData?.has(process_id)),
    ];
    return combinedCustomRequirements;
  };

  const getOwnerEntityRequirements = (
    processes: IdRequirementResultType[],
    selectedProcesses: OwnerIdRequirementsType[],
  ) => {
    const data =
      processes?.map((item) => {
        return {
          process_id: item?.id_process?.process_id,
          process_name: item?.id_process?.title,
          access_level: item?.id_access_level?.access_level === 1 ? false : true,
          has_access_level: ID_PROCESS_WITH_SAFE_DOC.includes(item?.id_process?.process_id),
          is_checked: true,
          is_mandatory: item?.details?.is_mandatory,
          note: item?.details?.note,
          has_high_priority: ENTITY_OWNER_ID_PROCESS_HIGH_PRIORITY.includes(item?.id_process?.process_id),
          has_children: item?.details?.profile_address_requirement_list ? true : false,
          profile_address_requirement_list:
            item?.details?.profile_address_requirement_list &&
            item?.details?.profile_address_requirement_list?.length > 0
              ? getProfileRequirementsDetails(item?.details?.profile_address_requirement_list)
              : DEFAULT_PROFILE_ADDRESS_TYPE,
        };
      }) || ([] as OwnerIdRequirementsType[]);
    const masterRequirementsData = selectedProcesses;
    const copyIdProcessMasterData = new Set(data?.map(({ process_id }) => process_id));
    const combinedRequirements = [
      ...data,
      ...masterRequirementsData?.filter(({ process_id }) => !copyIdProcessMasterData?.has(process_id)),
    ];
    return combinedRequirements;
  };

  const getInviteFields = (tAppInviteFields: TAppSelectionType, tAppTemplateList: TAppFormData[]) => {
    let result = [] as InviteFieldsType[];
    const specificTAppData =
      tAppTemplateList?.find((item) => item?.id === tAppInviteFields?.id) || ({} as TAppFormData);
    result = tAppInviteFields?.invite_fields?.map((item) => {
      const updatedInviteFields =
        specificTAppData?.invite_fields?.find((field) => item?.key === field?.key) || ({} as TAppFormInviteFields);
      return {
        ...item,
        defaultValue: updatedInviteFields?.value,
      };
    });
    return result;
  };

  const getTAppData = (tAppTemplateData: TAppFormData[], tAppList: TAppSelectionType[]) => {
    const tAppIdList = tAppTemplateData?.map((item) => item?.id) || ([] as string[]);
    const result = tAppList?.map((item) => {
      if (tAppIdList.includes(item?.id)) {
        return {
          ...item,
          invite_fields: getInviteFields(item, tAppTemplateData),
          is_checked: true,
        };
      }
      return {
        ...item,
      };
    });
    return result;
  };

  const handleRecipientsChange = (_event: React.SyntheticEvent, recipientList: string[]) => {
    let emailList: string[] = [];
    const errorEmail = recipientList.find((email) => !EMAIL_REGEX.test(email));
    if (errorEmail) {
      setRecipientInput(errorEmail);
      setIsRecipientEmailValid(true);
    } else {
      setIsRecipientEmailValid(false);
      emailList = recipientList?.filter((email) => EMAIL_REGEX.test(email));
      const updatedRecipientList = emailList?.map((item) => {
        return {
          first_name: '',
          middle_name: '',
          last_name: '',
          name: '',
          email: item,
        };
      });
      setRecipients(updatedRecipientList);
      setEmailRecipientList(emailList);
    }
  };

  const handleRecipientInput = (_event: React.SyntheticEvent, newValue: string) => {
    setIsRecipientEmailValid(false);
    setRecipientInput(newValue);
  };

  // use effects
  useEffect(() => {
    getDataOnLoad();
    dispatch(setIsFormValid(true));
    dispatch(setTAppFormData([]));
    dispatch(setTAppFormDataWithoutFields([]));
    dispatch(setTAppFieldData([]));
    if (isEntityInvite) {
      setIsEntityInvitation(true);
    }
  }, [isEntityInvite]);

  useEffect(() => {
    if (merchantTApps?.results?.length > 0) {
      setMerchantTAppData(merchantTApps?.results);
    }
  }, [merchantTApps]);

  useEffect(() => {
    if (individualIdProcessData?.length > 0 && !isEntityInvitation) {
      const individualProcesses: Array<IndividualIdRequirementsType> =
        getIndividualProcessesData(individualIdProcessData);
      setSelectedIndividualRequirements(individualProcesses);
      setIndividualRequirements(individualProcesses);
    } else if (entityIdProcessData?.length > 0 && isEntityInvitation) {
      const entityProcesses: Array<EntityIdRequirementsType> = getEntityProcessesData(entityIdProcessData);
      const ownerProcesses: Array<OwnerIdRequirementsType> = getOwnerIdRequirements(individualIdProcessData);
      setSelectedOwnerRequirements(ownerProcesses);
      setOwnerRequirements(ownerProcesses);
      setSelectedEntityRequirements(entityProcesses);
      setEntityRequirements(entityProcesses);
    }
  }, [individualIdProcessData, entityIdProcessData, isEntityInvitation]);

  useEffect(() => {
    if (customIdRequirementList?.length > 0) {
      const data: Array<CustomIdRequirementSelectionType> = customIdRequirementList?.map((item: CustomIdResultType) => {
        return {
          process_id: item?.id,
          process_name: item?.process_name,
          process_description: item?.process_description,
          access_level: false,
          is_checked: false,
        };
      });
      setCustomRequirements(data);
      setSelectedCustomRequirements(data);
    }
  }, [customIdRequirementList]);

  useEffect(() => {
    if (merchantTAppData?.length > 0) {
      const data = merchantTAppData?.map((item: TAppResultType) => {
        return {
          id: item?.id,
          name: item?.name,
          is_checked: false,
          invite_fields:
            item?.details?.invite_fields?.length > 0
              ? item?.details?.invite_fields?.filter((element) => element?.is_visible)
              : ([] as InviteFieldsType[]),
        };
      });
      setTApps(data);
      setDuplicateTAppList(data);
    }
  }, [merchantTAppData]);

  useEffect(() => {
    if (!isEntityInvitation) {
      setInviteAttributesData(specificInviteTemplate?.invite_attributes);
      const basicRequirements = getBasicIndividualRequirements(
        specificInviteTemplate?.id_requirements,
        selectedIndividualRequirements,
      );
      const customRequirementsData = getSavedCustomRequirements(
        specificInviteTemplate?.custom_id_requirements,
        selectedCustomRequirements,
      );
      const tAppData = getTAppData(specificInviteTemplate?.invite_attributes?.tapp_id_list, duplicateTAppList);
      setTApps(tAppData);
      setIndividualRequirements(basicRequirements);
      setCustomRequirements(customRequirementsData);
    }
  }, [
    specificInviteTemplate,
    isEntityInvitation,
    selectedIndividualRequirements,
    selectedCustomRequirements,
    duplicateTAppList,
  ]);

  useEffect(() => {
    if (isEntityInvitation) {
      setInviteAttributesData(specificInviteTemplate?.invite_attributes);
      const basicRequirements = getBasicEntityRequirements(
        specificInviteTemplate?.id_requirements,
        selectedEntityRequirements,
      );
      const ownerBasicRequirements = getOwnerEntityRequirements(
        specificInviteTemplate?.owner_id_requirements,
        selectedOwnerRequirements,
      );
      const customRequirementsData = getSavedCustomRequirements(
        specificInviteTemplate?.custom_id_requirements,
        selectedCustomRequirements,
      );
      const tAppData = getTAppData(specificInviteTemplate?.invite_attributes?.tapp_id_list, duplicateTAppList);
      setTApps(tAppData);
      setEntityRequirements(basicRequirements);
      setOwnerRequirements(ownerBasicRequirements);
      setCustomRequirements(customRequirementsData);
    }
  }, [
    specificInviteTemplate,
    isEntityInvitation,
    selectedEntityRequirements,
    selectedOwnerRequirements,
    selectedCustomRequirements,
    duplicateTAppList,
  ]);

  // Template
  return (
    <TCard>
      <TLoader loading={loading || loader} />
      <Box sx={{ p: 4 }} className="flex-basic-space-between">
        <Box
          sx={{ pl: 2 }}
          className={clsx('textColor-200 text-h2 font-weight-semibold titleBorderStyle', classes.titleBorder)}
        >
          {!isEntityInvitation ? t('newUserText') : t('inviteEntities')}
        </Box>
        <Box>
          <TButton icon={<Icon className="textColor-200">close_icon</Icon>} variant="text" onClick={closeForm} />
        </Box>
      </Box>
      <Box sx={{ px: 4, pt: 2, pb: 4 }}>
        <form>
          <Grid container spacing={2}>
            <Box className="flex-basic-space-between w-100">
              <Box sx={{ pl: 2 }} className="textColor-200 text-large font-weight-semibold">
                {t('recipientTitle')}
              </Box>
              <Box>
                <Box className="flex-basic-center">
                  <TButton
                    sx={{ mr: 2 }}
                    icon={<AddCircleOutlineIcon sx={{ pr: 1 }} className={clsx(classes.btnColor, 'icon-size-24')} />}
                    btnText={t('addLabel')}
                    btnWidthSize="button-w-80"
                    variant="text"
                    onClick={openRecipientsModal}
                  />
                  <FileButton
                    acceptedFileTypes={ACCEPTED_CSV_MIME_TYPES}
                    label="Upload CSV"
                    icon={<AddCircleOutlineIcon sx={{ pr: 1 }} className={clsx(classes.btnColor, 'icon-size-24')} />}
                    variant="text"
                    btnWidthSize="button-w-140"
                    isMultipleFile={false}
                    fileType={ACCEPTED_CSV_MIME_TYPES}
                    onChange={handleOnFileChange}
                  />
                </Box>
              </Box>
            </Box>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              sx={{ mb: 5 }}
              className={clsx(classes.dropdownStyle, classes.gridStyle)}
            >
              <Controller
                name="to"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TAutocomplete
                    {...field}
                    className={classes.recipientList}
                    multiple
                    freeSolo
                    disableClearable
                    limitTags={5}
                    autoSelect={true}
                    value={emailRecipientList}
                    inputValue={recipientInput}
                    options={[]}
                    disablePortal={true}
                    getOptionLabel={(option) => option || ''}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={handleRecipientsChange}
                    onInputChange={handleRecipientInput}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index: number) => (
                        <Box key={index}>
                          <Chip
                            {...getTagProps({ index })}
                            sx={{ mr: 1, mb: 1 }}
                            deleteIcon={<CloseIcon />}
                            variant="outlined"
                            label={option}
                            onDelete={() => handleChipDelete(option)}
                          />
                        </Box>
                      ))
                    }
                    renderInput={(params) => (
                      <TTextField
                        {...params}
                        variant="outlined"
                        error={isRecipientEmailValid}
                        helperText={isRecipientEmailValid && t('invalidEmail')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <TButton
                                disabled={recipients?.length === 0}
                                btnText={t('clearAll')}
                                variant="text"
                                onClick={() => setShowClearAll(true)}
                              />
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Box sx={{ pl: 2 }} className="textColor-200 text-large font-weight-semibold">
              {t('titleLabel')}
            </Box>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 5 }} className={classes.gridStyle}>
              <Controller
                name="title"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextField
                    {...field}
                    fullWidth
                    sx={{ my: 1 }}
                    value={title}
                    label="Type Title Text"
                    variant="outlined"
                    error={Boolean(errors.title)}
                    helperText={errors.title?.message}
                    inputProps={{ maxLength: MAX_CHAR_LIMIT.BASIC_CHAR_LENGTH }}
                    onChange={handleTitleChange}
                  />
                )}
              />
              <Box sx={{ mt: 1 }} className="text-small flex-basic-end font-weight-semibold textColor-300">
                {`${MAX_CHAR_LIMIT.BASIC_CHAR_LENGTH - titleCount} character(s) remaining`}
              </Box>
            </Grid>
            <Box sx={{ pl: 2 }} className="textColor-200 text-large font-weight-semibold">
              {t('optionsLabel')}
            </Box>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 5 }} className={classes.gridStyle}>
              <FormGroup>
                <FormControlLabel
                  control={<TCheckbox checked={termsAndConditions} onChange={handleTCChange} />}
                  label={t('inviteTNC')}
                />
                <FormControlLabel
                  control={<TCheckbox checked={isAutoApproved} onChange={handleAutoApprovedChange} />}
                  label={t('isAutoApproved')}
                />
              </FormGroup>
            </Grid>
            <Box>
              <Box sx={{ pl: 2 }} className="textColor-200 text-large font-weight-semibold">
                {t('inviteUserTapp')}
              </Box>
              <Box sx={{ pl: 2, pt: 1 }} className="textColor-400 text-medium font-weight-medium">
                {t('tappRecommendation')}
              </Box>
            </Box>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 5 }}>
              <FormGroup>
                {tApps?.map((item: TAppSelectionType) => (
                  <Box key={item?.id}>
                    <Box>
                      <FormControlLabel
                        control={<TCheckbox checked={item?.is_checked} onChange={() => handleTAppChange(item)} />}
                        label={item?.name}
                      />
                    </Box>
                    {item?.is_checked && item?.invite_fields?.length > 0 && (
                      <Box sx={{ mt: 2 }}>
                        <TAppForm tAppData={item} />
                      </Box>
                    )}
                  </Box>
                ))}
              </FormGroup>
            </Grid>
            <Box sx={{ pl: 2 }} className="textColor-200 text-large font-weight-semibold">
              {!isEntityInvitation ? t('idRequirementsTitle') : t('entityIdRequirementsTitle')}
            </Box>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 5 }}>
              {!isEntityInvitation ? (
                <IndividualIdRequirements
                  individualIdRequirements={individualRequirements}
                  updateRequirements={updateIndividualRequirements}
                />
              ) : (
                <EntityIdRequirements
                  entityIdRequirements={entityRequirements}
                  updateRequirements={updateEntityRequirements}
                />
              )}
            </Grid>
            {isEntityInvitation && (
              <Box sx={{ pl: 2 }} className="textColor-200 text-large font-weight-semibold">
                {t('beneficialOwnerTitle')}
              </Box>
            )}
            {isEntityInvitation && (
              <Grid item sm={12} md={12} lg={12} sx={{ mb: 5 }}>
                <OwnerIdRequirements
                  ownerIdRequirements={ownerRequirements}
                  updateOwnerRequirements={updateOwnerRequirements}
                />
              </Grid>
            )}
            {!isEntityInvitation && (
              <Box className="w-100" sx={{ mb: 5 }}>
                <Box className="flex-basic-space-between">
                  <Box sx={{ pl: 2 }} className="textColor-200 text-large font-weight-semibold">
                    {t('libraryDocumentLabel')}
                  </Box>
                  <Box>
                    <TButton
                      icon={<AddCircleOutlineIcon sx={{ pr: 1 }} className={clsx(classes.btnColor, 'icon-size-24')} />}
                      btnText={t('addDocumentLabel')}
                      btnWidthSize="button-w-80"
                      variant="text"
                      onClick={() => setOpenLibraryDocumentForm(true)}
                    />
                  </Box>
                </Box>
                <Box className="w-100" sx={{ pl: 2 }}>
                  {libraryCustomRequirements?.length > 0 && (
                    <FormGroup>
                      {libraryCustomRequirements?.map((item: LibraryDocumentSelectionType) => (
                        <Box className="flex-basic-space-between" key={item?.process_id}>
                          <Box>
                            <FormControlLabel
                              key={item?.process_id}
                              control={
                                <TCheckbox
                                  value={item?.process_id}
                                  checked={item?.is_checked}
                                  onChange={() => onLibraryCustomIdProcessChange(item)}
                                />
                              }
                              label={
                                <Box>
                                  <Box className="text-large font-weight-medium textColor-200" sx={{ mt: 4 }}>
                                    {item?.process_name}
                                  </Box>
                                  <Box sx={{ my: theme.spacing(1) }}>
                                    {item?.document_name_list?.map((item, index) => (
                                      <Box key={index} className="text-small textColor-300">
                                        {item}
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              }
                            />
                          </Box>
                          <Box>
                            <Box className="flex-basic-center">
                              <TButton
                                variant="text"
                                icon={<span className="icon-edit" />}
                                onClick={() => editLibraryCustomProcess(item)}
                              />
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </FormGroup>
                  )}
                </Box>
              </Box>
            )}
            <Box className="w-100 flex-basic-space-between">
              <Box sx={{ pl: 2 }} className="textColor-200 text-large font-weight-semibold">
                {t('customIdRequirementsTitle')}
              </Box>
              <Box>
                <TButton
                  icon={<AddCircleOutlineIcon sx={{ pr: 1 }} className={clsx(classes.btnColor, 'icon-size-24')} />}
                  btnText={t('addNewRequirements')}
                  btnWidthSize="button-w-80"
                  variant="text"
                  onClick={() => setOpenIdRequirementsModal(true)}
                />
              </Box>
            </Box>
            <Box className="w-100" sx={{ pl: 2, mb: 5 }}>
              {customRequirements?.length > 0 ? (
                <FormGroup>
                  {customRequirements?.map((item: CustomIdRequirementSelectionType) => (
                    <Box className="flex-basic-space-between" key={item?.process_id}>
                      <Box>
                        <FormControlLabel
                          key={item?.process_id}
                          control={
                            <TCheckbox
                              value={item?.process_id}
                              checked={item?.is_checked}
                              onChange={() => onCustomIdProcessChange(item)}
                            />
                          }
                          label={
                            <Box sx={{ mt: item?.process_description ? 2 : 0 }}>
                              <Box>{item?.process_name}</Box>
                              {item?.process_description && (
                                <TTooltip title={item?.process_description}>
                                  <Box
                                    className={clsx('flex-basic-center text-small textColor-200', classes.truncateText)}
                                  >
                                    {item?.process_description}
                                  </Box>
                                </TTooltip>
                              )}
                            </Box>
                          }
                        />
                      </Box>
                      <Box>
                        <Box className="flex-basic-center">
                          <TButton
                            variant="text"
                            icon={<span className="icon-edit" />}
                            onClick={() => editCustomProcess(item)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </FormGroup>
              ) : (
                <Box sx={{ my: 2 }} className="flex-basic-center text-medium font-weight-semibold">
                  {t('noIdRequirements')}
                </Box>
              )}
            </Box>
            <Box sx={{ pl: 2 }} className="textColor-200 text-large font-weight-semibold">
              {t('customMessageTitle')}
            </Box>
            <Grid item sm={12} md={12} lg={12} sx={{ mb: 3 }}>
              <Controller
                name="custom_message"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TTextArea
                    {...field}
                    fullWidth
                    value={message}
                    label="Type Message Text"
                    variant="outlined"
                    minRows={4}
                    inputProps={{ maxLength: MAX_CHAR_LIMIT.CHAR_LENGTH }}
                    error={Boolean(errors.custom_message)}
                    helperText={errors.custom_message?.message}
                    onChange={handleMessageChange}
                  />
                )}
              />
              <Box sx={{ mt: 1 }} className="text-small flex-basic-end font-weight-semibold textColor-300">
                {`${MAX_CHAR_LIMIT.CHAR_LENGTH - messageCount} character(s) remaining`}
              </Box>
              <Box className="text-medium textColor-200">{t('sendInviteDisclaimer')}</Box>
            </Grid>
          </Grid>
          <Box className="flex-basic-end">
            <TButton
              sx={{ mr: 4 }}
              btnText="Save as template"
              btnWidthSize="button-w-140"
              variant="outlined"
              disabled={!isValid || isSubmitting || !isFormValid}
              onClick={handleSubmit(saveTemplate)}
            />
            <TButton
              btnText="Send Invite(s)"
              btnWidthSize="button-w-140"
              variant="contained"
              disabled={
                !isValid ||
                isSubmitting ||
                recipients?.length === 0 ||
                !isFormValid ||
                !checkIsProfileRequirementsFulfilled()
              }
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
        <RecipientForm
          open={openRecipients}
          isEntityInvite={isEntityInvitation}
          recipients={recipients}
          closeForm={() => setOpenRecipients(false)}
          addRecipient={handleRecipientChange}
        />
        <AddIdRequirements
          open={openIdRequirementsModal}
          customIdRequirements={customRequirements}
          editCustomRequirement={editCustomRequirement}
          isEditActive={isEdit}
          closeForm={closeIdRequirementForm}
          addRequirement={addCustomRequirement}
          updateRequirements={updateCustomRequirements}
        />
        <LibraryDocumentForm
          open={openLibraryDocumentForm}
          libraryIdRequirements={libraryCustomRequirements}
          editLibraryRequirement={editLibraryCustomRequirement}
          addRequirement={addLibraryCustomRequirement}
          updateRequirements={updateLibraryCustomRequirements}
          closeForm={closeLibraryRequirementForm}
          openForm={() => setOpenLibraryDocumentForm(true)}
        />
        <TAlertDialog
          title={'Clear All'}
          portalName={PORTAL.MERCHANT}
          content={t('clearAllMessage')}
          showDialog={showClearAll}
          onCancel={() => setShowClearAll(false)}
          onConfirm={clearData}
        />
        <TAlertDialog
          title={'Send Invite'}
          portalName={PORTAL.MERCHANT}
          noBtnTitle={'Cancel'}
          yesBtnTitle={'Send Invite'}
          content={t('sendInviteDisclaimer')}
          showDialog={showSendInviteAlert}
          onCancel={() => setShowSendInviteAlert(false)}
          onConfirm={handleSubmit(onSubmit)}
        />
        <TSuccessDialog
          open={openSuccessDialog}
          title={t('successfullySentLabel')}
          message={isEntityInvitation ? t('inviteSuccessEntity') : t('inviteSuccessMsg')}
          onButtonClick={goToInvites}
        />
      </Box>
    </TCard>
  );
};

export default InviteForm;
