import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable, { MUIDataTableColumn } from 'mui-datatables';
import { Box, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import {
  colorPalette,
  TButton,
  TButtonToggle,
  TConfirmDialog,
  TDialog,
  TLinearProgress,
  TLoader,
  TPagination,
  TTooltip,
  theme,
  TSearchComponent,
} from '../../components/myde-react-components';
import {
  getAllInvites,
  getMerchantInvit,
  resendSelectedInvite,
  revokeSelectedInvite,
  selectInvitation,
  setCurrentTabId,
  setEmailData,
  setFilters,
  setInviteUpdateFlag,
  setSelectedApplication,
  setStatusData,
  updateMerchantInvit,
  setInviteType,
  resetMerchantInviteUpdatedSuccessfully,
} from '../../redux/feature/merchantInvite/inviteSlice';
import { formatDate } from '../../utils/utils';
import {
  INVITATION_STATUS,
  INVITED_TAB_STATUS_OPTIONS,
  INVITE_MESSAGE,
  INVITE_STATUS,
  NEW_APPLICANT_TAB_STATUS_OPTIONS,
  SORTING_PARAM,
  USER_TYPE,
  INVITE_TYPE_FILTER_OPTIONS,
} from '../../constants/constants';
import { useRouter } from '../../providers/custom-router-provider';
import * as ROUTES from './../../constants/routes';
import { useTheme } from '../../providers/custom-theme-provider';
import { ApplicationRequestType, InvitationType } from '../../types/applicantTypes';
import {
  DEFAULT_TABLE_OPTIONS,
  ITEMS_PER_PAGE_LIST,
} from '../../components/myde-react-components/src/constants/constants';
import { InviteResultType, InviteUserInfo } from '../../types/inviteTypes';
import ApplicantsFilterForm from '../../components/applicants/ApplicantsFilterForm';
import ConfirmWithReasonDialog from '../../components/common/ConfirmWithReason';
import { clearApplicationIdProcessList, updateApplication } from '../../redux/feature/applicant/applicantSlice';
import { useTranslation } from 'react-i18next';
import { PORTAL } from '../../components/myde-react-components/src/constants/portal';
import { debounce } from 'lodash';

export interface ApplicantIndexProps {
  status: string;
  portalName?: string;
}

interface StyleProps {
  currentThemes: Theme;
  matches: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>({
  tableContainer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      '& .MuiTable-root': {
        '& .MuiTableFooter-root': {
          display: 'none !important',
        },
      },
    },
  },

  actionIcons: {
    height: '0.8em !important',
    width: '0.8em !important',
  },
  pageContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  flexDisplay: {
    display: 'flex',
  },
  defaultBtnColor: {
    color: ({ currentThemes }) => `${currentThemes.palette.primary.main} !important`,
  },
  disabledBtn: {
    color: `${colorPalette.typoText.lighten4} !important`,
  },
  dialogStyle: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        width: ({ matches }) => (matches ? 460 : 375),
        '& .MuiDialogContent-root': {
          overflowY: 'unset',
        },
      },
    },

    '&.infoDialog': {
      '& #scrollableDiv': {
        '& .MuiBox-root:first-child': {
          paddingBottom: '0px!important',
        },
      },
    },
  },
  infoIcon: {
    color: `${colorPalette.typoText.lighten1} !important`,
    cursor: 'pointer',
  },
  progressBar: {
    height: '12px !important',
    borderRadius: 10,
    backgroundColor: `${colorPalette.containerBack.lighten2} !important`,
  },
});

const InviteListings = ({ status, portalName = PORTAL.MERCHANT }: ApplicantIndexProps) => {
  // Constants
  const matches = portalName === PORTAL.MERCHANT;

  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { currentThemes } = useTheme();
  const styleProps: StyleProps = {
    currentThemes: currentThemes,
    matches,
  };
  const classes = useStyles(styleProps);
  const primaryColor = currentThemes.palette.primary.main;
  const { routeTo } = useRouter();

  // Redux Values
  const {
    inviteData,
    loading,
    count,
    inviteUpdated,
    inviteList,
    statusData,
    emailData,
    applicantFilters,
    currentTabId,
    selectedApplication,
    inviteType,
    merchantInviteUpdatedSuccessfully,
  } = useSelector(selectInvitation);

  // State Values
  const [open, setOpen] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [form, setForm] = useState({} as any);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [invitationList, setInviteList] = useState([] as InvitationType[]);
  const [applicationList, setApplicationList] = useState([] as InviteResultType[]);
  const [showRevokeModal, setRvokeModalFlag] = useState(false);
  const [selectedInvitation, setSelectedInvite] = useState({} as InvitationType);
  const [ordering, setOrdering] = useState<string>('-created_on');
  const [filterStatus, setFilterStatus] = useState([] as string[]);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [selectedUserInfo, setSelectedUserInfo] = useState({} as InviteUserInfo);
  const [showReconsiderDialog, setShowReconsiderDialog] = useState(false);
  const [inviteTypeFilter, setInviteTypeFilter] = useState(USER_TYPE.ALL);

  // Use Effects
  useEffect(() => {
    dispatch(setFilters({}));
    dispatch(setInviteType(''));
  }, []);

  useEffect(() => {
    if (inviteType) {
      setPage(1);
      getApplications(1);
    }
  }, [inviteType]);

  useEffect(() => {
    if (statusData?.length > 0) {
      const data = statusData?.map((item) => item?.id);
      setFilterStatus(data);
    } else {
      setFilterStatus([]);
    }
  }, [statusData]);

  useEffect(() => {
    if (Object.keys(applicantFilters)?.length > 0) {
      getApplications(page, applicantFilters);
    }
  }, [applicantFilters]);

  useEffect(() => {
    getApplications(1, applicantFilters);
    setPage(1);
  }, [open]);

  useEffect(() => {
    getApplications(page, applicantFilters);
  }, [page, pageSize]);

  useEffect(() => {
    let selectedTab = 0;
    switch (status) {
      case INVITE_STATUS.APPROVED:
        selectedTab = 1;
        break;
      case INVITE_STATUS.REJECTED:
        selectedTab = 2;
        break;
      case INVITE_STATUS.INVITED:
        selectedTab = 3;
        setOrdering('-modified_on');
        break;
    }
    dispatch(setInviteType(''));
    dispatch(setCurrentTabId(selectedTab));
  }, [status]);

  useEffect(() => {
    dispatch(setFilters({}));
  }, [currentTabId]);

  useEffect(() => {
    if (inviteUpdated) {
      getApplications(page, applicantFilters);
      dispatch(setInviteUpdateFlag(false));
    }
  }, [inviteUpdated]);

  useEffect(() => {
    if (inviteData?.length > 0) {
      setApplicationList(invitations(inviteData));
    } else {
      setApplicationList([]);
    }
  }, [inviteData]);

  useEffect(() => {
    if (inviteList?.length > 0) {
      setInviteList(invitations(inviteList));
    }
  }, [inviteList]);

  useEffect(() => {
    resetFilters();
  }, [currentTabId]);

  useEffect(() => {
    if (merchantInviteUpdatedSuccessfully) {
      getApplications(1, applicantFilters);
      setPage(1);
      dispatch(resetMerchantInviteUpdatedSuccessfully());
    }
  }, [merchantInviteUpdatedSuccessfully]);

  // Methods
  const getApplications = (pageNum: number, extraFilters?: { name?: string; email?: string; status_in?: object }) => {
    let currentStatus;
    if (status === INVITE_STATUS.READY_FOR_REVIEW) {
      currentStatus = {
        status_in: `{${INVITE_STATUS.STARTED},${INVITE_STATUS.PENDING_REVIEW},${INVITE_STATUS.UNDER_REVIEW}}`,
      };
    } else if (status === INVITE_STATUS.INVITED) {
      currentStatus = {
        status_in: `{${INVITATION_STATUS.ACCOUNT_DECLINED},${INVITATION_STATUS.REVOKED},${INVITATION_STATUS.ACCOUNT_SIGNED_UP},${INVITATION_STATUS.CREATED}}`,
      };
    } else {
      currentStatus = status && { status: status };
    }

    const statusFilter = extraFilters?.status_in ? { ...extraFilters } : { ...currentStatus };

    const applicantFormFilters = extraFilters ? { ...extraFilters, ...statusFilter } : { ...currentStatus };
    const inviteFilter =
      !inviteType || inviteType === USER_TYPE.ALL
        ? {}
        : {
            invite_type: inviteType,
          };

    const formData: ApplicationRequestType = {
      page: pageNum,
      limit: pageSize,
      is_parent_application: true,
      ...inviteFilter,
      ...applicantFormFilters,
    };
    if (ordering) {
      formData['ordering'] = ordering;
    }
    dispatchActionForApplications(formData);
  };

  const dispatchActionForApplications = (formData: ApplicationRequestType) => {
    if (status !== INVITE_STATUS.INVITED) {
      dispatch(getMerchantInvit(formData));
    } else {
      dispatch(getAllInvites(formData));
    }
  };

  const handleClose = () => {
    getApplications(page);
    setOpen(false);
  };
  const deleteInvitation = (data: { note: string }) => {
    if (data.note) {
      form['note'] = data?.note;
    }
    dispatch(updateMerchantInvit(form));
    handleClose();
  };

  const invitations = (inviteMe: InviteResultType[] | InvitationType[]) => {
    const arr: any[] = [];
    inviteMe?.forEach((element: any) => {
      const name = element?.account?.first_name
        ? `${element?.account?.first_name || ''} ${element?.account?.last_name || ''}`
        : `${element?.name || element?.account?.name || ''}`;
      const obj = {
        id: element?.id,
        invite_type: element?.invite_type || element?.invitation?.invite_type,
        name: name,
        email: element?.account?.email || element?.email,
        flag: element?.flag_count,
        submitted_on: formatDate(element?.submitted_on),
        status: INVITE_MESSAGE[element?.status],
        reviewed_on: formatDate(element?.reviewed_on),
        invited_by: element?.invitation?.invited_by,
        invited_on: element?.invitation ? formatDate(element?.invitation?.invited_on) : formatDate(element?.invited_on),
        accepted_on: element?.invitation
          ? formatDate(element?.invitation?.accepted_on)
          : formatDate(element?.accepted_on),
        completion_percentage: element?.completion_percentage,
      };
      arr.push(obj);
    });
    return arr;
  };
  const selectUser = (inv: any) => {
    if (status !== INVITE_STATUS.INVITED) {
      const selectedInvite = getSelectedInviteData(inv, inviteData);
      const query = {
        inviteId: selectedInvite?.id,
        type: selectedInvite?.account?.type,
        userId: selectedInvite?.account?.trellis_uid,
      };
      dispatch(clearApplicationIdProcessList());
      routeTo(ROUTES.USER, false, '', query);
    }
  };

  const openInfoDialog = (inv: any) => {
    const selectedInvite = getSelectedInviteData(inv, applicationList);
    setSelectedUserInfo(selectedInvite);
    setShowInfoDialog(true);
  };

  const acceptInvitation = (inv: any) => {
    const selectedInvite = getSelectedInviteData(inv, inviteData);
    const data = {
      status: INVITE_STATUS.APPROVED,
      id: selectedInvite?.id || '',
    };
    dispatch(updateMerchantInvit(data));
  };
  const rejectInvitation = (inv: any) => {
    setOpen(true);
    const selectedInvite = getSelectedInviteData(inv, inviteData);
    const data = {
      status: INVITE_STATUS.REJECTED,
      id: selectedInvite?.id,
    };
    setForm(data);
  };
  const getSelectedInviteData = (inv: any, list: any[]): any => {
    const currentId: string = inv?.rowData[0] || '';
    return list?.find((invite: InviteResultType | InvitationType) => invite?.id === currentId) || {};
  };
  const resendInvitation = (inv: InvitationType) => {
    dispatch(resendSelectedInvite(inv?.id));
  };
  const revokeInvite = (inv: InvitationType) => {
    setRvokeModalFlag(true);
    setSelectedInvite(inv);
  };
  const revokeInvitation = () => {
    dispatch(revokeSelectedInvite(selectedInvitation?.id));
    setRvokeModalFlag(false);
  };
  const onRowChange = (value: number) => {
    setPage(1);
    setPageSize(value);
  };
  const onPageChange = (value: any) => {
    setPage(value);
  };
  const onCancelDeny = () => {
    setShowReconsiderDialog(false);
  };
  const updateApplicationStatus = async (id: string, status: string, note: string) => {
    const data = { id: id, status: status, note: note };
    dispatch(updateApplication(data));
  };
  const reconsiderApplication = (application: InviteResultType) => {
    setShowReconsiderDialog(true);
    dispatch(setSelectedApplication(application));
  };
  const confirmReconsider = async (data: { note: string }) => {
    await updateApplicationStatus(selectedApplication?.id, INVITE_STATUS.UNDER_REVIEW, data?.note);
    onCancelDeny();
    await getApplications(page);
    dispatch(setSelectedApplication({} as InviteResultType));
  };
  const customBodyRender = (value: any) => {
    return <Box className="text-large textColor-200">{value}</Box>;
  };
  const getActions = (tableMeta: any) => {
    let template = <></>;
    if (status === INVITE_STATUS.READY_FOR_REVIEW) {
      const applicationDetail: InviteResultType = getSelectedInviteData(tableMeta, inviteData);
      const allowApproveReject = applicationDetail?.status === INVITE_STATUS.STARTED;
      template = (
        <Box className={classes.flexDisplay}>
          <TTooltip title="Approve">
            <Box>
              <TButton
                variant="text"
                icon={
                  <Box
                    color="success"
                    className={clsx(classes.actionIcons, 'actionIconFont-24 icon-checked success-color-state')}
                  />
                }
                disabled={allowApproveReject}
                onClick={() => {
                  acceptInvitation(tableMeta);
                }}
              ></TButton>
            </Box>
          </TTooltip>
          <TTooltip title="Decline">
            <Box>
              <TButton
                variant="text"
                icon={
                  <Box
                    color="error"
                    className={clsx(classes.actionIcons, 'actionIconFont-24 icon-forbid error-color-state')}
                  />
                }
                disabled={allowApproveReject}
                onClick={() => {
                  rejectInvitation(tableMeta);
                }}
              ></TButton>
            </Box>
          </TTooltip>
          <ConfirmWithReasonDialog
            showDialog={open}
            classNameDailog="declineBoxOpacityInviteList"
            onCancel={handleClose}
            onConfirm={deleteInvitation}
            message="Are you sure you want to decline the invitation?"
            title="Confirm"
            noteLabel="Decline Reason"
          />
        </Box>
      );
    } else if (status === INVITE_STATUS.INVITED) {
      const invitationDetail: InvitationType = getSelectedInviteData(tableMeta, inviteList);
      const allowRevoke = invitationDetail?.status !== INVITATION_STATUS.REVOKED;
      template = (
        <Box className={classes.flexDisplay}>
          <TTooltip title="Revoke">
            <Box className="margin-right-12">
              <TButton
                variant="text"
                icon={
                  <span
                    className={clsx({
                      [classes.actionIcons]: true,
                      ['icon-arrow-go-back actionIconFont-24']: true,
                    })}
                  />
                }
                className={clsx({
                  [classes.defaultBtnColor]: allowRevoke,
                  [classes.disabledBtn]: !allowRevoke,
                  ['btnWithIcon']: true,
                })}
                disabled={!allowRevoke}
                onClick={() => {
                  revokeInvite(invitationDetail);
                }}
              ></TButton>
            </Box>
          </TTooltip>
          <TTooltip title="Resend">
            <Box>
              <TButton
                variant="text"
                icon={
                  <span
                    className={clsx({
                      [classes.actionIcons]: true,
                      ['icon-download-resend actionIconFont-24']: true,
                    })}
                  />
                }
                className={clsx({
                  [classes.defaultBtnColor]: true,
                  ['btnWithIcon']: true,
                })}
                onClick={() => {
                  resendInvitation(invitationDetail);
                }}
              ></TButton>
            </Box>
          </TTooltip>
          <TConfirmDialog
            portalName={PORTAL.MERCHANT}
            showDialog={showRevokeModal}
            classNameConfirmDailog="confirmDialogBoxOpacity"
            onCancelClick={() => {
              setRvokeModalFlag(false);
            }}
            onConfirmClick={revokeInvitation}
            confirmationMessage="Are you sure to revoke this invitation?"
            title="Confirm Revoke"
          />
        </Box>
      );
    } else if (status === INVITE_STATUS.REJECTED) {
      const applicationDetail: InviteResultType = getSelectedInviteData(tableMeta, inviteData);
      template = (
        <Box className={classes.flexDisplay}>
          <TTooltip title="Reconsider">
            <Box className="margin-right-12">
              <TButton
                variant="text"
                icon={<span className={clsx(classes.actionIcons, 'icon-reconsider actionIconFont-24')} />}
                className={clsx(classes.defaultBtnColor, 'btnWithIcon')}
                onClick={() => {
                  reconsiderApplication(applicationDetail);
                }}
              ></TButton>
            </Box>
          </TTooltip>
          <ConfirmWithReasonDialog
            showDialog={showReconsiderDialog}
            title="Please Confirm"
            yesBtnTitle="Reconsider"
            noBtnTitle="Cancel"
            classNameDailog="declineBoxOpacityInviteList"
            message="Are you sure to reconsider this application?"
            onCancel={onCancelDeny}
            onConfirm={confirmReconsider}
            noteLabel="Reconsider Reason"
          />
        </Box>
      );
    }
    return template;
  };

  const filterRecords = debounce((searchValue: string) => {
    if (searchValue) {
      const filters = {
        name: searchValue,
      };
      getApplications(1, filters);
    } else {
      getApplications(page);
    }
  }, 300);

  const extraFilterHandler = () => {
    setShowFilterDialog(true);
  };

  const checkTabStatus = () => {
    if (status === INVITE_STATUS.READY_FOR_REVIEW || status === INVITE_STATUS.INVITED) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async () => {
    let filters = {};
    const emailText = {};
    const statuses = {};
    if (emailData) {
      Object.assign(emailText, { email: emailData });
    }
    if (filterStatus?.length > 0) {
      Object.assign(statuses, { status_in: `{${filterStatus?.toString()}}` });
    }
    filters = {
      ...emailText,
      ...statuses,
    };
    if (Object.keys(filters)?.length > 0) {
      const combinedFilters =
        Object.keys(applicantFilters)?.length > 0
          ? {
              ...applicantFilters,
              ...filters,
            }
          : { ...filters };
      dispatch(setFilters(combinedFilters));
    } else {
      getApplications(page);
    }
    setShowFilterDialog(false);
  };

  const handleChange = () => {
    setShowFilterDialog(false);
  };

  const getStatusOptions = () => {
    if (status === INVITE_STATUS.READY_FOR_REVIEW) {
      return NEW_APPLICANT_TAB_STATUS_OPTIONS;
    } else if (status === INVITE_STATUS.INVITED) {
      return INVITED_TAB_STATUS_OPTIONS;
    } else {
      return [];
    }
  };

  const onColumnSortChange = (changedColumn: string, direction: string) => {
    let orderingValue = '';
    if (changedColumn === SORTING_PARAM.ACTION || changedColumn === SORTING_PARAM.STATUS) {
      return;
    } else if (changedColumn === SORTING_PARAM.NAME || changedColumn === SORTING_PARAM.EMAIL) {
      orderingValue = 'account__' + changedColumn;
    } else if (changedColumn === SORTING_PARAM.FLAG) {
      orderingValue = 'flag_count';
    } else if (changedColumn === 'invited_on' || changedColumn === 'accepted_on') {
      if (status === INVITE_STATUS.READY_FOR_REVIEW) {
        orderingValue = 'invitation__' + changedColumn;
      } else {
        orderingValue = changedColumn;
      }
    } else {
      orderingValue = changedColumn;
    }

    setOrdering(direction === 'desc' ? '-' + orderingValue : orderingValue);

    const currentStatus =
      status === INVITE_STATUS.READY_FOR_REVIEW
        ? { status_in: `{${INVITE_STATUS.STARTED}, ${INVITE_STATUS.UNDER_REVIEW}, ${INVITE_STATUS.PENDING_REVIEW}}` }
        : { status };

    const formData = {
      page: page,
      limit: pageSize,
      ...currentStatus,
      ordering: direction === 'desc' ? '-' + orderingValue : orderingValue,
    };

    dispatchActionForApplications(formData);
  };

  const getInfoDetails = (title: string, value: string | number) => {
    return (
      <Grid item xs={6} md={6} sm={6}>
        <Box className={clsx('text-medim font-weight-regular textColor-400')}>{title}</Box>
        <Box className={clsx('text-large font-weight-semibold text-break-word textColor-200')}>
          {title === 'Application progress' && (
            <Box sx={{ mt: 1 }}>
              <TLinearProgress className={classes.progressBar} variant="determinate" value={+value || 0} />
            </Box>
          )}
          {title !== 'Application progress' && (value || '-')}
        </Box>
      </Grid>
    );
  };

  const getStatus = (inv: any) => {
    const selectedInvite = getSelectedInviteData(inv, applicationList);
    return selectedInvite?.status;
  };

  const getInviteType = (inv: any) => {
    const selectedInvite =
      status === INVITE_STATUS.INVITED
        ? getSelectedInviteData(inv, invitationList)
        : getSelectedInviteData(inv, applicationList);
    return selectedInvite?.invite_type;
  };

  const handleInviteTypeChange = (_event: React.MouseEvent<HTMLElement>, newValue: string) => {
    setInviteTypeFilter(newValue);
    dispatch(setInviteType(newValue));
  };

  // Constants
  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'id',
      options: { customBodyRender, display: false },
    },
    {
      name: 'invite_type',
      label: 'Invite Type',
      options: { customBodyRender, display: false },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          const inviteType = getInviteType(tableMeta);
          const type = inviteType === USER_TYPE.ENTITY ? 'Entity' : '';
          return (
            <Box className="flex-basic-space-between">
              <TTooltip title={value}>
                <Box
                  className={clsx(
                    {
                      [classes.defaultBtnColor]: true,
                      ['removeCursorPointer']: status === INVITE_STATUS.INVITED,
                      ['textTruncateLargeWidth']: status === INVITE_STATUS.APPROVED,
                    },
                    'cursorPointer font-weight-semibold text-large textTruncate',
                  )}
                  onClick={() => {
                    getStatus(tableMeta) === INVITE_MESSAGE.STARTED ? openInfoDialog(tableMeta) : selectUser(tableMeta);
                  }}
                >
                  {value}
                </Box>
              </TTooltip>
              {type && (
                <TTooltip title={type}>
                  <Box sx={{ ml: 1 }} className="entityTagContainer text-large textColor-200 flex-basic-center">
                    <Box>E</Box>
                  </Box>
                </TTooltip>
              )}
            </Box>
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customBodyRender: (value: string) => {
          return (
            <TTooltip title={value}>
              <Box
                className={clsx({
                  ['text-large textColor-200 cursorDefault textTruncate']: true,
                  ['textTruncateLargeWidth']: status === INVITE_STATUS.APPROVED,
                })}
              >
                {value}
              </Box>
            </TTooltip>
          );
        },
        display:
          status === INVITE_STATUS.INVITED ||
          status === INVITE_STATUS.APPROVED ||
          status === INVITE_STATUS.REJECTED ||
          status === INVITE_STATUS.READY_FOR_REVIEW,
      },
    },
    {
      name: 'flag',
      label: 'Flags',
      options: { customBodyRender, display: status !== INVITE_STATUS.INVITED },
    },
    {
      name: 'invited_on',
      label: 'Invited on',
      options: {
        customBodyRender,
        display: status === INVITE_STATUS.INVITED || status === INVITE_STATUS.READY_FOR_REVIEW,
      },
    },
    {
      name: 'accepted_on',
      label: status !== INVITE_STATUS.INVITED ? 'Accepted on' : 'Accepted/Dismissed on',
      options: {
        customBodyRender,
        display: status === INVITE_STATUS.READY_FOR_REVIEW || status === INVITE_STATUS.INVITED,
      },
    },
    {
      name: 'submitted_on',
      label: 'Submitted on',
      options: { customBodyRender, display: status !== INVITE_STATUS.INVITED },
    },
    {
      name: 'reviewed_on',
      label: status === INVITE_STATUS.APPROVED ? 'Approved on' : 'Declined on',
      options: { customBodyRender, display: status === INVITE_STATUS.APPROVED || status === INVITE_STATUS.REJECTED },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender,
        display: status === INVITE_STATUS.READY_FOR_REVIEW || status === INVITE_STATUS.INVITED,
        sort: false,
      },
    },
    {
      name: 'action',
      label: 'Actions',
      options: {
        display:
          status === INVITE_STATUS.READY_FOR_REVIEW ||
          status === INVITE_STATUS.INVITED ||
          status === INVITE_STATUS.REJECTED,
        customBodyRender: (_value: any, tableMeta: any) => {
          return getActions(tableMeta);
        },
        sort: false,
      },
    },
  ];
  const options: any = {
    ...DEFAULT_TABLE_OPTIONS,
    rowsPerPage: pageSize,
    onColumnSortChange: onColumnSortChange,
  };
  const resetFilters = () => {
    dispatch(setEmailData(''));
    dispatch(setStatusData([]));
    dispatch(setFilters({}));
    getApplications(page);
  };

  const resetForm = () => {
    dispatch(setEmailData(''));
    dispatch(setStatusData([]));
    dispatch(setFilters({}));
  };

  // HTML
  return (
    <Box className={classes.tableContainer}>
      <Box sx={{ mb: 6 }} className="flex-basic-end">
        <Box className="flex-basic-center align-items-end">
          <Box sx={{ mr: 2 }}>
            <TButtonToggle
              value={inviteTypeFilter}
              buttons={INVITE_TYPE_FILTER_OPTIONS}
              onChange={handleInviteTypeChange}
            />
          </Box>
          <Box>
            <TSearchComponent
              placeholder="Search by Name"
              onSearchUpdate={filterRecords}
              extraFilterHandler={extraFilterHandler}
              resetFilters={resetFilters}
              appliedFilters={applicantFilters}
              appliedFilterCount={Object.keys(applicantFilters)?.length}
            />
          </Box>
        </Box>
      </Box>
      <TLoader loading={loading} />
      <MUIDataTable
        title=""
        data={status === INVITE_STATUS.INVITED ? invitationList : applicationList}
        columns={columns}
        options={options}
      />
      <Box className={classes.pageContainer}>
        <TPagination
          primaryColor={primaryColor}
          rowsPerPageOptions={ITEMS_PER_PAGE_LIST}
          rowsPerPage={pageSize}
          count={Math.ceil(count / pageSize)}
          onRowChange={onRowChange}
          onPageChange={onPageChange}
          page={page}
        />
      </Box>
      <TDialog
        open={showFilterDialog}
        className={classes.dialogStyle}
        title="Filters"
        onClose={handleChange}
        onResetFilter={resetForm}
      >
        <ApplicantsFilterForm options={getStatusOptions()} showStatusFilter={checkTabStatus()} onSubmit={onSubmit} />
      </TDialog>
      <TDialog open={showInfoDialog} className={clsx(classes.dialogStyle, 'infoDialog')}>
        <Box sx={{ mt: 5 }}>
          <Box className="flex-basic-space-between" sx={{ pb: theme.spacing(5) }}>
            <Box className="text-h2 font-weight-semibold textColor-200">{t('information')}</Box>
            <Box className="flex-basic-center" onClick={() => setShowInfoDialog(false)}>
              <Box className="icon-close icon-size-24 textColor-200 cursorPointer"></Box>
            </Box>
          </Box>
          <Box sx={{ pt: theme.spacing(5) }}>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {getInfoDetails(t('name'), selectedUserInfo?.name)}
              {getInfoDetails(t('email'), selectedUserInfo?.email)}
            </Grid>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              {getInfoDetails(t('invitedOn'), formatDate(selectedUserInfo?.invited_on))}
              {getInfoDetails(t('invitedBy'), selectedUserInfo?.invited_by)}
            </Grid>
            <Grid container spacing={2}>
              {getInfoDetails(t('acceptedOn'), formatDate(selectedUserInfo?.accepted_on))}
              {getInfoDetails(t('applicationProgress'), selectedUserInfo?.completion_percentage)}
            </Grid>
          </Box>
        </Box>
      </TDialog>
    </Box>
  );
};

export default InviteListings;
