import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { InputAdornment } from '@mui/material';

import { ClickHandlerType, REGEX, TButton, TTextField } from '../myde-react-components';
import { TAX_ID_VISIBILITY_LEVEL } from '../../constants/constants';
import { EntityDetail } from '../../types/entityTypes';
import {
  addNewTaxId,
  getTaxId,
  getUnmaskedTaxId,
  selectEntity,
} from '../../redux/feature/id-process/entity/entitySlice';

interface AddTaxIdProps {
  setIsEditClicked: ClickHandlerType;
  isEditClicked: boolean;
}

const AddTaxId = ({ setIsEditClicked, isEditClicked }: AddTaxIdProps) => {
  //Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //Redux values
  const { assignedEntityDetails, unmaskedTaxId } = useSelector(selectEntity);

  //State variables
  const [enteredTaxId, setEnteredTaxId] = useState('');
  const [selectedEntity, setSelectedEntity] = useState({} as EntityDetail);
  const [disableSave, setDisableSave] = useState(false);
  const [passwordShown, setPasswordShown] = useState(true);

  //Use Effects
  useEffect(() => {
    if (assignedEntityDetails?.id) {
      setSelectedEntity(assignedEntityDetails);
    }
  }, [assignedEntityDetails]);

  useEffect(() => {
    if (isEditClicked && selectedEntity?.id) {
      setDisableSave(true);
      const getTaxIdData = {
        entity_uid: selectedEntity?.trellis_uid,
        params: {
          visibility_level: TAX_ID_VISIBILITY_LEVEL.SOURCE,
        },
      };
      dispatch(getUnmaskedTaxId(getTaxIdData));
    }
  }, [isEditClicked, selectedEntity]);

  useEffect(() => {
    if (unmaskedTaxId) {
      setEnteredTaxId(unmaskedTaxId);
    }
  }, [unmaskedTaxId]);

  //Methods
  const handleTaxIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (REGEX?.TAX_ID?.test(event?.target?.value)) {
      setEnteredTaxId(event?.target?.value);
      setDisableSave(false);
    } else {
      return;
    }
  };

  const handleSaveClick = async () => {
    const data = {
      entity_uid: selectedEntity?.trellis_uid,
      formData: {
        tax_id: enteredTaxId,
      },
    };
    await dispatch(addNewTaxId(data));
    const getTaxIdData = {
      entity_uid: selectedEntity?.trellis_uid,
    };
    dispatch(getTaxId(getTaxIdData));
    setIsEditClicked(false);
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box>
      <form>
        <Box sx={{ pt: 4, pr: 5 }}>
          <TTextField
            value={enteredTaxId}
            fullWidth
            label={t('enterTaxId')}
            type={passwordShown ? 'text' : 'password'}
            onChange={handleTaxIdChange}
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <TButton
                      variant="text"
                      onClick={togglePassword}
                      onMouseDown={handleMouseDownPassword}
                      icon={
                        <>
                          <Box className="infoIcon">
                            {passwordShown ? (
                              <VisibilityOutlinedIcon
                                sx={{ mt: 1, mr: 2 }}
                                fontSize="large"
                                className="textColor-200 icon-size-20"
                              />
                            ) : (
                              <VisibilityOffOutlinedIcon
                                sx={{ mt: 1, mr: 2 }}
                                fontSize="large"
                                className="textColor-200 icon-size-20"
                              />
                            )}
                          </Box>
                        </>
                      }
                    />
                  </InputAdornment>
                </>
              ),
            }}
          />
        </Box>
        <Box sx={{ pt: 4, pr: 5 }} className="flex-basic-end">
          <TButton
            btnText="Save"
            variant="contained"
            disabled={!enteredTaxId || disableSave}
            btnWidthSize="button-w-140"
            onClick={handleSaveClick}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddTaxId;
