import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddTaxId from './AddTaxId';
import TaxIdDetails from './TaxIdDetails';
import { TButton, TLoader, theme } from '../myde-react-components';
import { getTaxId, selectEntity } from '../../redux/feature/id-process/entity/entitySlice';
import { EntityDetail } from '../../types/entityTypes';

const TaxIdIndex = () => {
  //Constants
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  //Redux values
  const { taxId, loading, assignedEntityDetails } = useSelector(selectEntity);

  //State variables
  const [selectedEntity, setSelectedEntity] = useState({} as EntityDetail);
  const [enteredTaxId, setEnteredTaxId] = useState('');
  const [isEditClicked, setIsEditClicked] = useState(false);

  //Use Effects
  useEffect(() => {
    if (assignedEntityDetails?.id) {
      setSelectedEntity(assignedEntityDetails);
    }
  }, [assignedEntityDetails]);

  useEffect(() => {
    if (selectedEntity?.id) {
      const data = {
        entity_uid: selectedEntity?.trellis_uid,
      };
      dispatch(getTaxId(data));
    }
  }, [selectedEntity]);

  useEffect(() => {
    if (taxId) {
      setEnteredTaxId(taxId);
    } else {
      setEnteredTaxId('');
    }
  }, [taxId]);

  //Methods
  const handleEditClick = () => {
    setIsEditClicked(true);
  };

  const handleCancelClick = () => {
    setIsEditClicked(false);
  };

  return (
    <Box sx={{ pt: theme.spacing(9), pl: 5 }}>
      <TLoader loading={loading} />
      <Box className="flex-basic-space-between">
        <Box className="font-weight-semibold text-h2">{t('taxId')}</Box>
        {selectedEntity?.trellis_uid && !isEditClicked && enteredTaxId && (
          <Box sx={{ pr: 5 }}>
            <TButton
              btnText="Edit"
              variant="text"
              icon={<Box sx={{ pr: theme.spacing(1) }} className="icon-edit" />}
              onClick={handleEditClick}
            />
          </Box>
        )}
        {selectedEntity?.trellis_uid && isEditClicked && (
          <Box sx={{ pr: 5 }}>
            <TButton
              btnText="Cancel"
              variant="text"
              icon={<Box sx={{ pr: theme.spacing(1) }} className="icon-close" />}
              onClick={handleCancelClick}
            />
          </Box>
        )}
      </Box>
      {enteredTaxId && !isEditClicked ? (
        <TaxIdDetails taxId={enteredTaxId} selectedEntity={selectedEntity} />
      ) : (
        <AddTaxId setIsEditClicked={setIsEditClicked} isEditClicked={isEditClicked} />
      )}
    </Box>
  );
};

export default TaxIdIndex;
